import { Api } from '@axiom/ui';
import {
  Calendar,
  CalendarEventType,
  Candidate,
  Contact,
  TimeZoneIana,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CalendarApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  createCalendar(body: {
    candidateId?: Candidate['id'];
    contactId?: Contact['id'];
    timezone: TimeZoneIana;
  }) {
    return super.write<{
      data: {
        id: Calendar['id'];
        timezone: TimeZoneIana;
      };
    }>({
      endpoint: `/calendars`,
      method: 'POST',
      body,
    });
  }

  readCalendar(calendarId: Calendar['id']) {
    return super.read<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}`,
      method: 'GET',
    });
  }

  refreshCalendar(calendarId: Calendar['id']) {
    return super.invalidate(`/calendars/${calendarId}`);
  }

  createCalendarEvent(calendarId: Calendar['id'], body: CalendarEventType) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/events`,
      method: 'POST',
      body,
    });
  }

  createCalendarEvents(calendarId: Calendar['id'], body: CalendarEventType[]) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/batchEvents`,
      method: 'POST',
      body,
    });
  }

  refreshCalendarEvents(calendarId: Calendar['id']) {
    return super.invalidate(`/calendars/${calendarId}/events`);
  }

  updateCalendarEvent(
    calendarId: Calendar['id'],
    eventId: CalendarEventType['id'],
    body: CalendarEventType
  ) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/events/${eventId}`,
      method: 'PATCH',
      body,
    });
  }

  updateCalendarEvents(
    calendarId: Calendar['id'],
    body: {
      [key: CalendarEventType['id']]: CalendarEventType;
    }
  ) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/batchEvents`,
      method: 'PATCH',
      body,
    });
  }

  deleteCalendarEvent(
    calendarId: Calendar['id'],
    eventId: CalendarEventType['id']
  ) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/events/${eventId}`,
      method: 'DELETE',
    });
  }

  deleteCalendarEvents(
    calendarId: Calendar['id'],
    body: CalendarEventType['id'][]
  ) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/batchEvents`,
      method: 'DELETE',
      body,
    });
  }
}

export const CalendarApi = new CalendarApiClass();
