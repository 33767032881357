import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import {
  Loader,
  ServiceWorkerProvider,
  Suspense,
  LayoutBleed,
} from '@axiom/ui';
import { connect } from 'react-redux';

import DefaultLayout from './layouts/default';
import GoogleTagManager from './components/GoogleTagManager/GoogleTagManager';
import { NotFoundPage } from './pages/NotFoundPage';
import { ConvertUserIdsPage } from './pages/ConvertUserIdsPage';
import { OpportunityDetailPage } from './pages/OpportunityDetailPage';
import { TalentDetailPage } from './pages/TalentDetailPage';
import { BenchPage } from './pages/BenchPage';
import { TalentPage } from './pages/TalentPage';
import { OpportunitiesPage } from './pages/OpportunitiesPage';
import { WorkspacePage } from './pages/WorkspacePage';
import { GoogleAnalytics } from './components/GoogleAnalytics/GoogleAnalytics';
import { EnvUtil } from './utils/env-util';
import { shouldRouteUserToWorkspace } from './utils/roles';
import { PreloadedUserStore } from './stores/preloaded-user-store';
import { UserShape } from './models/user';
import { WindowUtil } from './utils/window-util';
import { Page } from './components/Page/Page';
import { CandidateBios } from './components/CandidateBios/CandidateBios';

const QueryHandler = () => {
  const navigate = useNavigate();
  const callBack = (query, replace) => {
    if (replace) {
      navigate(query, { replace: true });
    } else {
      navigate(query);
    }
  };

  useEffect(() => {
    WindowUtil.setNavigateCallback(callBack);
  }, []);
  return null;
};

const RouterComponent = ({ user }) => {
  const toRouteOnload = shouldRouteUserToWorkspace(user)
    ? '/workspace'
    : '/bench';

  return (
    <>
      <QueryHandler />
      <ServiceWorkerProvider />
      <Routes>
        <Route path="" element={<Navigate to={toRouteOnload} />} />
        <Route path="bench/*" element={<BenchPage />} />
        <Route path="workspace">
          <Route path="opportunities/*" element={<WorkspacePage />} />
          <Route path="bench-reminders" element={<WorkspacePage />} />
          <Route path="incomplete-bios" element={<WorkspacePage />} />
          <Route path="feedback" element={<WorkspacePage />} />
          <Route path="talent-management" element={<WorkspacePage />} />
          <Route path="" element={<Navigate to="opportunities" replace />} />
          <Route path="*" element={<Navigate to="opportunities" replace />} />
        </Route>
        <Route path="opportunities/*" element={<OpportunitiesPage />} />
        <Route
          path="opportunity-detail/:id/*"
          element={<OpportunityDetailPage />}
        />
        <Route path="talent/*" element={<TalentPage />} />

        <Route
          path="talent/:candidateId/bio/anonymous?"
          element={
            <Page>
              <LayoutBleed>
                <DefaultLayout simple>
                  <Suspense fallback={<Loader type="bars" />}>
                    <CandidateBios />
                  </Suspense>
                </DefaultLayout>
              </LayoutBleed>
            </Page>
          }
        />
        <Route
          path="talent/:candidateId/download/bio/anonymous?"
          element={
            <LayoutBleed>
              <Suspense fallback={<Loader type="bars" />}>
                <CandidateBios />
              </Suspense>
            </LayoutBleed>
          }
        />

        <Route path="talent-detail/:id/*" element={<TalentDetailPage />} />
        <Route path="convert-user-ids" element={<ConvertUserIdsPage />} />

        <Route path="404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <GoogleAnalytics />
      <GoogleTagManager
        gtmId={EnvUtil.gtmAccountId}
        additionalEvents={{ platform: 'react-stack' }}
      />
    </>
  );
};

RouterComponent.propTypes = {
  user: UserShape.isRequired,
};

export const Router = connect(state => ({
  user: PreloadedUserStore.select(state),
}))(RouterComponent);
