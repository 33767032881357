import React, { useState } from 'react';
import { ContextMenu } from '@axiom/ui';
import { Candidate, Opportunity, User } from '@axiom/validation';

import { isSalesUser } from '../../utils/roles';
import { TalentInterviewAvailabilityModalEdit } from '../TalentInterviewAvailabilityModalEdit/TalentInterviewAvailabilityModalEdit';
import { CandidateCalendarMenuContent } from '../CandidateCalendarMenuContent/CandidateCalendarMenuContent';

export const CandidateCalendarMenu = ({
  user,
  candidate,
  icon,
  opportunityId,
}: {
  user: User;
  candidate: Candidate;
  icon: JSX.Element;
  opportunityId?: Opportunity['id'];
}) => {
  const [
    showEditInterviewAvailabilityModal,
    setShowEditInterviewAvailabilityModal,
  ] = useState<boolean>(false);

  return (
    <>
      {!isSalesUser(user) && (
        <ContextMenu
          name="INTERVIEW_AVAILABILITY_MENU"
          direction="left"
          anchor={icon}
        >
          <CandidateCalendarMenuContent
            user={user}
            onEditAvailability={setShowEditInterviewAvailabilityModal}
          />
        </ContextMenu>
      )}

      {showEditInterviewAvailabilityModal && (
        <TalentInterviewAvailabilityModalEdit
          opportunityId={opportunityId}
          candidate={candidate}
          user={user}
          onClose={() => setShowEditInterviewAvailabilityModal(false)}
        />
      )}
    </>
  );
};
