import React, { Component } from 'react';
import { func, number } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Button,
  Gutter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Suspense,
  Tab,
  Tabs,
} from '@axiom/ui';

import LawSchoolsManagementView from '../LawSchoolsManagementView/LawSchoolsManagementView';
import { loadUsers } from '../../redux/actions/users';
import { usersLastUpdatedSelector } from '../../redux/selectors/users';

import { AreasOfStudyManagementView } from './AreasOfStudyManagementView';
import UserManagementView from './UserManagementView';
import TagManagementView from './TagManagementView';

const UPDATE_INTERVAL = 120000; // 2 minutes

export class AdminFunctionsModalComponent extends Component {
  componentDidMount() {
    const now = Date.now();
    const { usersUpdated } = this.props;
    if (now - usersUpdated > UPDATE_INTERVAL) this.props.loadUsers();
  }

  render() {
    const {
      props: { onClose },
    } = this;

    return (
      <Modal size="large" name="AdminFunctionsModal">
        <ModalHeader onClose={onClose}>Admin Functions</ModalHeader>
        <ModalSection>
          <Tabs>
            <Tab name="Users" label="Users">
              <Gutter bottom="8px" />
              <UserManagementView />
            </Tab>
            <Tab name="Tags" label="Tags">
              <Gutter bottom="8px" />
              <TagManagementView />
            </Tab>
            <Tab name="Schools" label="Schools">
              <Gutter bottom="8px" />
              <LawSchoolsManagementView />
            </Tab>
            <Tab name="Area of Study" label="Area of Study">
              <Gutter bottom="8px" />
              <Suspense>
                <AreasOfStudyManagementView />
              </Suspense>
            </Tab>
          </Tabs>
        </ModalSection>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AdminFunctionsModalComponent.defaultProps = {
  loadUsers: () => {},
  usersUpdated: 0,
};

AdminFunctionsModalComponent.propTypes = {
  loadUsers: func,
  onClose: func.isRequired,
  usersUpdated: number,
};

const mapStateToProps = createStructuredSelector({
  usersUpdated: usersLastUpdatedSelector,
});

const mapDispatchToProps = {
  loadUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminFunctionsModalComponent);
