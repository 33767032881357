import React, { useState } from 'react';
import {
  CurrentEngagementCandidateStatuses,
  InConsiderationCandidateStatuses,
  NoLongerInConsiderationCandidateStatuses,
  PreviousEngagementCandidateStatuses,
} from '@axiom/const';
import {
  Button,
  EmptyLayout,
  Gutter,
  Layout,
  Paragraph,
  ParagraphHeader,
  useApi,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import NoResults from '../../public/svg/no-results-binoculars.svg';
import { CandidateOpportunityLegacyApi } from '../../api/candidate-opportunities-api-legacy';
import { TalentOpportunityContainer } from '../TalentOpportunityContainer/TalentOpportunityContainer';
import { TalentOpportunityCardTypeNames } from '../TalentOpportunityCard/TalentOpportunityCard';
import { AppFindOppForCandidateModalStore } from '../../stores/app-find-opp-for-candidate-modal-store';

import { TalentOpportunityTabFilter } from './TalentOpportunityTabFilter';

export const TalentOpportunityTab = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [{ data: candidateOpportunities }] = useApi(
    CandidateOpportunityLegacyApi.readCandidateOpportunities(candidateId)
  );

  const [filters, setFilters] = useState([]);

  const inConsiderationOpps = candidateOpportunities.filter(opp =>
    filters.length > 0
      ? filters
          .filter(filter => InConsiderationCandidateStatuses.includes(filter))
          .includes(opp.candidateStatus)
      : InConsiderationCandidateStatuses.includes(opp.candidateStatus)
  );

  const currentEngagementOpps = candidateOpportunities.filter(opp =>
    filters.length > 0
      ? filters
          .filter(filter => CurrentEngagementCandidateStatuses.includes(filter))
          .includes(opp.candidateStatus)
      : CurrentEngagementCandidateStatuses.includes(opp.candidateStatus)
  );

  const previousEngagementOpps = candidateOpportunities.filter(opp =>
    filters.length > 0
      ? filters
          .filter(filter =>
            PreviousEngagementCandidateStatuses.includes(filter)
          )
          .includes(opp.candidateStatus)
      : PreviousEngagementCandidateStatuses.includes(opp.candidateStatus)
  );

  const noLongerInConsiderationOpps = candidateOpportunities.filter(opp =>
    filters.length > 0
      ? filters
          .filter(filter =>
            NoLongerInConsiderationCandidateStatuses.includes(filter)
          )
          .includes(opp.candidateStatus)
      : NoLongerInConsiderationCandidateStatuses.includes(opp.candidateStatus)
  );

  return (
    <div data-test="TALENT_OPPORTUNITIES">
      <Gutter vertical="8px" />
      <Layout position="right">
        <Button
          name="OPP_ADD_BUTTON"
          pattern="secondary"
          variation="filled"
          onClick={() =>
            AppFindOppForCandidateModalStore.openModal(
              [candidateId],
              candidateId
            )
          }
        >
          Add Opportunities
        </Button>
      </Layout>
      {/* APCORE-2418 - Need to retain the old style DropdownTree for now because the new
          one goes up and out of view on smaller screens */}
      <TalentOpportunityTabFilter onFilterChange={setFilters} />
      <Gutter vertical="8px" />
      {(inConsiderationOpps.length > 0 || filters.length === 0) && (
        <TalentOpportunityContainer
          type={TalentOpportunityCardTypeNames.inConsideration}
          candidateOpportunities={inConsiderationOpps}
        />
      )}
      {(currentEngagementOpps.length > 0 || filters.length === 0) && (
        <TalentOpportunityContainer
          type={TalentOpportunityCardTypeNames.currentEngagements}
          candidateOpportunities={currentEngagementOpps}
        />
      )}
      {(previousEngagementOpps.length > 0 || filters.length === 0) && (
        <TalentOpportunityContainer
          type={TalentOpportunityCardTypeNames.previousEngagements}
          candidateOpportunities={previousEngagementOpps}
        />
      )}
      {(noLongerInConsiderationOpps.length > 0 || filters.length === 0) && (
        <TalentOpportunityContainer
          type={TalentOpportunityCardTypeNames.noLongerInConsideration}
          candidateOpportunities={noLongerInConsiderationOpps}
        />
      )}
      {filters &&
        filters.length > 0 &&
        ![
          inConsiderationOpps,
          currentEngagementOpps,
          previousEngagementOpps,
          noLongerInConsiderationOpps,
        ].some(arr => arr.length) && (
          <EmptyLayout
            graphic={<NoResults width="117px" height="117px" />}
            name="NO_OPPORUNTITIES_FOUND"
          >
            <Gutter bottom="16px" />
            <ParagraphHeader>No Opportunities Found</ParagraphHeader>
            <Gutter bottom="16px" />
            <Paragraph>
              No matching opportunities found. Try adding more filters or
              clearing your current selection of filters.
            </Paragraph>
          </EmptyLayout>
        )}
    </div>
  );
};
