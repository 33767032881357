import React, { PureComponent } from 'react';
import startCase from 'lodash/startCase';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formPropTypes } from 'redux-form';
import {
  LanguageConst,
  CountryCodesConst,
  StateCodesConst,
  CandidatesConst,
} from '@axiom/const';
import {
  ContextMenu,
  ContextMenuItem,
  Gutter,
  Header4,
  IconButton,
  Layout,
  PracticeAreaUtil,
  Tooltip,
} from '@axiom/ui';
import { generateCandidateSlug } from '@axiom/utils';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';

import { EnvUtil } from '../../utils/env-util';
import TalentResume from '../TalentResume/TalentResume';
import CertificationList from '../Certifications/Certifications';
import EducationList from '../EducationList/EducationList';
import HorizontalRule from '../HorizontalRule/HorizontalRule';
import TagsDropdownField from '../TagsDropdownField/TagsDropdownField';
import TalentProfileEditForm from '../TalentProfileEditForm/TalentProfileEditForm';
import UnorderedList from '../UnorderedList/UnorderedList';
import sortLanguages from '../../utils/sortLanguages';
import { Tag, TagRow } from '../../styled';
import { talentShape } from '../../models/talent';
import { submitForm } from '../../redux/actions/form';
import { TALENT_EDIT } from '../../redux/constants';
import { DateUtil } from '../../utils/date-util';
import { formatCurrency, formatPercentage } from '../../utils/parsers';
import { sortByName } from '../../utils/sort';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { PreloadedTagsStore } from '../../stores/preloaded-tags-store';
import { FormTalentProfileEditStore } from '../../stores/form-talent-profile-edit-store';
import { CandidateCollaboratorsStore } from '../../stores/candidate-collaborators-store';
import { TalentAvailabilityNotes } from '../TalentAvailabilityNotes/TalentAvailabilityNotes';
import { AxiomForTalentUtil } from '../../utils/axiom-for-talent';
import { TalentInterviewAvailability } from '../TalentInterviewAvailability/TalentInterviewAvailability';
import { TalentOpportunityContainer } from '../TalentOpportunityContainer/TalentOpportunityContainer';
import { TalentOpportunityCardTypeNames } from '../TalentOpportunityCard/TalentOpportunityCard';

import { TalentProfileDetailAvailability } from './TalentProfileDetailAvailability';
import {
  BreakCell,
  TPDCell,
  DetailColumn,
  DetailContainer,
  DrawerContainer,
  FlagIconWrapper,
  SpanTalentFullName,
  TPDFRow,
  WideCell,
  EmailButtonWrapper,
  TPDFTitle,
} from './TalentProfileDetailFormStyles';

const {
  ProfileStatuses: { Alum },
  availabilityPreferencesUpdatedByTypes,
} = CandidatesConst;

const { AbbreviatedCountryCode } = CountryCodesConst;
const { AbbreviatedStateCode } = StateCodesConst;

const INITIAL_STATE = {
  isResumeShown: false,
};

class TalentProfileDetailForm extends PureComponent {
  get experienceTags() {
    const { experiences } = this.props.talent;
    if (!experiences || experiences.length === 0) return [];

    const tags = experiences.reduce((t, experience) => {
      if (experience.tags) {
        experience.tags.forEach(tag => {
          if (tag) {
            t[tag.id] = tag;
          }
        });
      }
      return t;
    }, {});

    return Object.values(tags).sort(sortByName);
  }

  get fieldTags() {
    const fieldTags = this.props.talent.tags;
    if (!fieldTags || fieldTags.length === 0) return [];

    const tagObjs = fieldTags.reduce((tObj, tagId) => {
      const foundTag = this.props.tags.find(fTag => fTag.value === tagId);
      if (foundTag) {
        tObj[foundTag.value] = foundTag;
      }
      return tObj;
    }, {});

    return Object.values(tagObjs).sort(sortByName);
  }

  get formattedAddress() {
    const { talent, isEnvoySalesUser } = this.props;

    const state = talent.addressState || '';
    const zip = talent.addressZip || '';

    const parts = [
      talent.address1,
      talent.address2,
      talent.addressCity,
      isEnvoySalesUser ? state : `${state} ${zip}`.trim(),
      talent.addressCountry,
    ];

    return parts.filter(part => !!part).join(', ');
  }

  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
  }

  handleSave = () => {
    const { form, dispatch } = this.props;
    dispatch(submitForm(form, TALENT_EDIT));
  };

  handleEditClick = () =>
    FormTalentProfileEditStore.openModal(this.props.talent);

  closeResume = () => {
    this.setState({ isResumeShown: false });
  };

  toggleResume = () => {
    this.setState(state => ({ isResumeShown: !state.isResumeShown }));
  };

  getDaysSinceAvailabilityUpdated = () =>
    DateUtil.getDaysSinceNow(
      this.props.talent.availabilityPreferencesUpdatedAt || new Date()
    );

  getLastUpdatedByText = ({
    profileStatus,
    availabilityPreferencesUpdatedByType,
  }) => {
    if (profileStatus === Alum) {
      return 'Alum talent must confirm availability in Axiom for Talent';
    }

    switch (availabilityPreferencesUpdatedByType) {
      case availabilityPreferencesUpdatedByTypes.Talent:
        return 'Last Updated by Talent';
      case availabilityPreferencesUpdatedByTypes.HQ:
        return 'Last Updated in HQ';
      default:
        return 'Last Updated: Profile Default';
    }
  };

  getEstimatedRate = ({
    displayLowHourlyRate,
    displayHighHourlyRate,
    currency,
  }) => {
    if (displayLowHourlyRate && displayHighHourlyRate) {
      return `${formatCurrency(
        displayLowHourlyRate,
        currency
      )} - ${formatCurrency(displayHighHourlyRate)}`;
    }
    return '--';
  };

  render() {
    const {
      closeResume,
      formattedAddress,
      handleEditClick,
      handleSave,
      toggleResume,
      props: {
        isDetail,
        talent,
        practiceAreas,
        talent: {
          barredLocationNames,
          ownerUser,
          addressCountry,
          addressState,
        },
        user,
        isEnvoySalesUser,
      },
      state: { isResumeShown },
    } = this;

    const Container = isDetail ? DetailContainer : DrawerContainer;
    const Column = isDetail ? DetailColumn : 'div';
    const canEditProfile = !isEnvoySalesUser;
    const homeLocation = (() => {
      const country = addressCountry
        ? AbbreviatedCountryCode(addressCountry) || addressCountry
        : '';
      const state = addressState
        ? AbbreviatedStateCode(addressState) || addressState
        : '';

      if (!state && addressCountry) {
        return addressCountry;
      } else if (!country && addressState) {
        return addressState;
      }

      return [country, state].filter(loc => !!loc).join(' - ');
    })();

    const talentFullNameWithFlag = (
      <div>
        {talent.isFlagged ? <FlagIconWrapper /> : null}
        <SpanTalentFullName>{talent.calculatedDisplayName}</SpanTalentFullName>
      </div>
    );
    const { practiceArea, specialty } =
      PracticeAreaUtil.getPracticeAreaAndSpecialty(
        practiceAreas,
        talent.practiceAreaId
      );

    const emailsUrl = (function () {
      const { personalEmail, activeWorkEmail } = talent;

      const addresses = [personalEmail, activeWorkEmail]
        .filter(ads => !!ads)
        .join('; ');
      return addresses.length ? `mailTo: ${addresses}` : null;
    })();

    return (
      <Container data-test="PROFILE_CONTAINER">
        <Column data-test="DETAILS">
          <TalentInterviewAvailability candidate={talent} user={user} />
          <Layout position="right" horizontalGutter="8px">
            <ContextMenu
              name="TALENT_PROFILE_DOWNLOAD_MENU"
              direction="left"
              anchor={
                <IconButton
                  icon="download"
                  pattern="secondary"
                  variation="minimal"
                />
              }
            >
              {AxiomForTalentUtil.isFullBiosByStatus(talent.profileStatus) &&
                talent.isProfileShared && (
                  <ContextMenuItem
                    onClick={() => AxiomForTalentUtil.downloadBio(talent.id)}
                    name="DOWNLOAD_BIO"
                    cssClass="__tpdf-menu-item"
                  >
                    Download Bio
                  </ContextMenuItem>
                )}
              <ContextMenuItem
                name="DOWNLOAD_ANONYMOUS_BIO"
                onClick={() =>
                  AxiomForTalentUtil.downloadAnonymousBio(talent.id)
                }
              >
                Download Anonymous Bio
              </ContextMenuItem>
            </ContextMenu>
            <ContextMenu direction="left">
              <ContextMenuItem
                onClick={() => {
                  AxiomForTalentUtil.openAltSpv(
                    generateCandidateSlug(talent, practiceAreas)
                  );
                }}
              >
                Open in A.L.T.
              </ContextMenuItem>
              {AxiomForTalentUtil.isFullBiosByStatus(talent.profileStatus) &&
                talent.isProfileShared && (
                  <ContextMenuItem
                    name="OPEN_BIO"
                    onClick={() => AxiomForTalentUtil.openBio(talent.id)}
                  >
                    Open Bio
                  </ContextMenuItem>
                )}
              <ContextMenuItem
                name="OPEN_ANONYMOUS_BIO"
                onClick={() => AxiomForTalentUtil.openAnonymousBio(talent.id)}
              >
                Open Anonymous Bio
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() => AxiomForTalentUtil.openResume(talent.id)}
              >
                Open Legacy Bio
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() =>
                  AxiomForTalentUtil.openAnonymousResume(talent.id)
                }
              >
                Open Legacy Anon Bio
              </ContextMenuItem>
              <ContextMenuItem onClick={() => toggleResume()}>
                Open Resume
              </ContextMenuItem>
              {AxiomForTalentUtil.canViewAft(talent, user) && (
                <ContextMenuItem
                  onClick={() => AxiomForTalentUtil.openAftProfile(talent.id)}
                >
                  Open in A.F.T.
                </ContextMenuItem>
              )}
            </ContextMenu>

            {canEditProfile && (
              <IconButton
                icon="pencil"
                pattern="secondary"
                variation="minimal"
                onClick={handleEditClick}
                name="TALENTPROFILEEDITPEN"
              />
            )}
          </Layout>
          <Gutter bottom="8px" />
          <TalentAvailabilityNotes candidate={talent} />
          <HorizontalRule />
          <Gutter bottom="8px" />
          <TalentOpportunityContainer
            type={TalentOpportunityCardTypeNames.currentEngagements}
            candidateOpportunities={talent.candidacyEngagements}
          />
          <HorizontalRule />
          <TalentProfileEditForm />
          <TPDFRow noCells>
            <Gutter bottom="1rem">
              <Header4>Professional Details</Header4>
            </Gutter>
          </TPDFRow>
          <TPDFRow>
            <WideCell isDetail={isDetail}>
              <UnorderedList title="Name" values={talentFullNameWithFlag} />
            </WideCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Integration ID"
                values={talent.integrationId}
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title={
                  <>
                    Employment Status{' '}
                    <Tooltip>
                      This is the talent’s status in our HR systems. If you feel
                      this status is incorrect, please{' '}
                      <a
                        href={EnvUtil.reportAnIssueUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        submit a ticket for HRIS
                      </a>
                    </Tooltip>
                  </>
                }
                dataTestId="EMPLOYMENTSTATUS"
                values={talent.employmentStatus}
              />
            </TPDCell>
            {!isDetail && <BreakCell />}
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Talent Owner"
                values={ownerUser ? ownerUser.fullName : null}
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Bar Admissions"
                values={barredLocationNames}
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList title="Home Location" values={homeLocation} />
            </TPDCell>
            <BreakCell />
            <WideCell>
              <UnorderedList
                title="Practice Area & Focus"
                values={[
                  <>
                    <span data-test="PRACTICEAREA">
                      {practiceArea.name ?? '--'}
                    </span>
                    {specialty.name && <>{' - '}</>}
                    <span data-test="SPECIALTY">{specialty.name ?? ''}</span>
                  </>,
                ]}
              />
            </WideCell>
            <BreakCell />
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Years of Experience"
                values={new CandidateYearsOfExperienceModel(
                  talent.yearsOfExperience
                ).formatYearsOfExperience()}
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Employee Type"
                values={talent.employeeType}
              />
            </TPDCell>
            {!isDetail && <BreakCell />}
            <WideCell>
              <UnorderedList
                title="Languages"
                values={talent.languages.sort(sortLanguages).map(lang => {
                  const parts = [
                    lang.name,
                    startCase(
                      LanguageConst.LANGUAGE_PROFICIENCIES[
                        lang.languageProficiency
                      ]
                    ),
                    startCase(lang.languageSkill),
                    lang.languageCEFR,
                  ];
                  return parts.filter(v => v).join('; ');
                })}
              />
            </WideCell>
          </TPDFRow>
          <HorizontalRule />
          <TPDFRow noCells>
            <Gutter bottom="1rem">
              <Header4>Contact Information</Header4>
            </Gutter>
          </TPDFRow>
          <TPDFRow>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Phone"
                values={[
                  <>
                    Cell{' '}
                    <span data-test="CELLPHONE">
                      {talent.mobilePhone ?? ' -- '}
                    </span>
                  </>,
                  <>Home {talent.homePhone ?? ' -- '}</>,
                ]}
              />
            </TPDCell>
            <WideCell isDetail={isDetail}>
              <TPDFTitle className="no-margin">Email</TPDFTitle>
              <EmailButtonWrapper>
                <IconButton
                  icon="mail"
                  to={emailsUrl ?? '#'}
                  pattern="secondary"
                  variation="minimal"
                />
                <UnorderedList
                  title=""
                  values={[
                    <>
                      Personal{' '}
                      {talent.personalEmail ? (
                        <a
                          data-test="PERSONALEMAIL"
                          href={`mailto:${talent.personalEmail}`}
                        >
                          {talent.personalEmail}
                        </a>
                      ) : (
                        <span data-test="PERSONALEMAIL">--</span>
                      )}
                    </>,
                    <>
                      Axiom{' '}
                      {talent.activeWorkEmail ? (
                        <a
                          data-test="WORKEMAIL"
                          href={`mailto:${talent.activeWorkEmail}`}
                        >
                          {talent.activeWorkEmail}
                        </a>
                      ) : (
                        <span data-test="WORKEMAIL">--</span>
                      )}
                    </>,
                  ]}
                />
              </EmailButtonWrapper>
            </WideCell>
            <BreakCell />
            <WideCell>
              <UnorderedList title="Address" values={formattedAddress} />
            </WideCell>
          </TPDFRow>

          <HorizontalRule />
          <TPDFRow noCells>
            <Gutter bottom="1rem">
              <Header4>Compensation</Header4>
            </Gutter>
          </TPDFRow>
          <TPDFRow>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Job Profile"
                values={talent.occupationType}
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Employee Type"
                values={talent.employeeType}
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Base Annual Salary"
                values={[
                  formatCurrency(talent.baseAnnualSalary, talent.currency),
                ]}
                dataTestId="BASEANNUALSALARY"
              />
            </TPDCell>
            <BreakCell />
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Base Hourly Compensation"
                values={[
                  formatCurrency(talent.hourlyCompensation, talent.currency),
                ]}
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Target Bonus"
                values={[formatPercentage(talent.bonusPercentage)]}
                dataTestId="BONUSPERCENTAGE"
              />
            </TPDCell>
            <TPDCell isDetail={isDetail}>
              <UnorderedList
                title="Total Annual Compensation"
                values={[formatCurrency(talent.compensation, talent.currency)]}
              />
            </TPDCell>
            {talent.addressCountry === 'United States' && (
              <>
                <TPDCell isDetail={isDetail}>
                  <span data-test="ESTIMATEDRATE_CONTAINER">
                    <UnorderedList
                      title="Estimated Rate"
                      values={this.getEstimatedRate(talent)}
                    />
                  </span>
                </TPDCell>
                <WideCell>
                  <span data-test="FULLTIMEWEEKLYRATE_CONTAINER">
                    <UnorderedList
                      title="Full-time Weekly Rate"
                      values={[
                        formatCurrency(
                          talent.displayWeeklyRate,
                          talent.currency
                        ),
                      ]}
                    />
                  </span>
                </WideCell>
              </>
            )}
          </TPDFRow>
          <HorizontalRule />
          <TalentProfileDetailAvailability candidate={talent} />

          {!isDetail && <HorizontalRule />}
        </Column>
        <Column>
          <Header4>Skills</Header4>
          {this.experienceTags.length > 0 && (
            <TagRow marginBottom={12} skillWidth={canEditProfile}>
              {this.experienceTags.map(tag => (
                <Tag key={tag.id} tagType="skills">
                  {tag.name}
                </Tag>
              ))}
            </TagRow>
          )}
          <Header4>Tags</Header4>
          {canEditProfile ? (
            <TagsDropdownField
              name="tags"
              onAfterChange={handleSave}
              inputTestId="TagsDropdownField"
            />
          ) : (
            this.fieldTags.length > 0 && (
              <TagRow marginBottom={12}>
                {this.fieldTags.map(tag => (
                  <Tag key={tag.value}>{tag.label}</Tag>
                ))}
              </TagRow>
            )
          )}
          <EducationList candidateId={talent.id} degrees={talent.degrees} />
          <CertificationList
            candidateId={talent.id}
            certifications={talent.certifications}
          />
        </Column>
        {isResumeShown && (
          <TalentResume onCancel={closeResume} talent={talent} />
        )}
      </Container>
    );
  }
}

TalentProfileDetailForm.defaultProps = {
  isDetail: false,
};

TalentProfileDetailForm.propTypes = {
  ...formPropTypes,
  isDetail: bool,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  tags: PreloadedTagsStore.getDataShape().isRequired,
  talent: talentShape.isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
  isEnvoySalesUser: bool.isRequired,
};

TalentProfileDetailForm.defaultFormProps = {
  destroyOnUnmount: false,
  enableReinitialize: true,
  persistentSubmitErrors: true,
};

const mapStateToProps = state => ({
  practiceAreas: PreloadedPracticeAreasStore.selectData(state),
  collaborators: CandidateCollaboratorsStore.selectData(state),
  user: PreloadedUserStore.select(state),
  tags: PreloadedTagsStore.selectData(state),
});

export default connect(mapStateToProps)(
  reduxForm(TalentProfileDetailForm.defaultFormProps)(TalentProfileDetailForm)
);
