import React from 'react';
import styled from 'styled-components';
import { oneOf, string } from 'prop-types';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import AddUser from '../../../assets/icons/icon-add-user.svg';
import AlertFilled from '../../../assets/icons/icon-alert-filled.svg';
import ArrowDown from '../../../assets/icons/icon-arrow-down.svg';
import ArrowLeft from '../../../assets/icons/icon-arrow-left.svg';
import ArrowRight from '../../../assets/icons/icon-arrow-right.svg';
import ArrowUp from '../../../assets/icons/icon-arrow-up.svg';
import Avatar from '../../../assets/icons/icon-avatar.svg';
import Bell from '../../../assets/icons/icon-bell.svg';
import Bold from '../../../assets/icons/icon-bold.svg';
import BulletPoint from '../../../assets/icons/icon-bullet-point.svg';
import Calendar from '../../../assets/icons/icon-calendar.svg';
import CenterAlign from '../../../assets/icons/icon-center-align.svg';
import Checkmark from '../../../assets/icons/icon-checkmark.svg';
import CircleCheckFilled from '../../../assets/icons/icon-circle-check-filled.svg';
import CircleInfo from '../../../assets/icons/icon-circle-info.svg';
import CircleInfoFilled from '../../../assets/icons/icon-circle-info-filled.svg';
import CircleWaitingFilled from '../../../assets/icons/icon-circle-waiting-filled.svg';
import Clock from '../../../assets/icons/icon-clock.svg';
import ClockFilled from '../../../assets/icons/icon-clock-filled.svg';
import CollapseAll from '../../../assets/icons/icon-collapse-all.svg';
import Copy from '../../../assets/icons/icon-copy.svg';
import Dollar from '../../../assets/icons/icon-dollar.svg';
import DollarFilled from '../../../assets/icons/icon-dollar-filled.svg';
import Download from '../../../assets/icons/icon-download.svg';
import ExpandAll from '../../../assets/icons/icon-expand-all.svg';
import Eye from '../../../assets/icons/icon-eye.svg';
import EyeHidden from '../../../assets/icons/icon-eye-hidden.svg';
import Filter from '../../../assets/icons/icon-filter.svg';
import FilterFilled from '../../../assets/icons/icon-filter-filled.svg';
import Handle from '../../../assets/icons/icon-handle.svg';
import Heart from '../../../assets/icons/icon-heart.svg';
import HeartFilled from '../../../assets/icons/icon-heart-filled.svg';
import Italic from '../../../assets/icons/icon-italic.svg';
import LeftAlign from '../../../assets/icons/icon-left-align.svg';
import Mail from '../../../assets/icons/icon-mail.svg';
import Menu from '../../../assets/icons/icon-menu.svg';
import Pencil from '../../../assets/icons/icon-pencil.svg';
import Placeholder from '../../../assets/icons/icon-placeholder.svg';
import PopoutIcon from '../../../assets/icons/icon-popout.svg';
import PlusIcon from '../../../assets/icons/icon-plus.svg';
import OpenIcon from '../../../assets/icons/icon-open.svg';
import Reset from '../../../assets/icons/icon-reset.svg';
import RightAlign from '../../../assets/icons/icon-right-align.svg';
import Settings from '../../../assets/icons/icon-settings.svg';
import Search from '../../../assets/icons/icon-search.svg';
import Share from '../../../assets/icons/icon-share.svg';
import Sort from '../../../assets/icons/icon-sort.svg';
import SortAscending from '../../../assets/icons/icon-sort-ascending.svg';
import SortDescending from '../../../assets/icons/icon-sort-descending.svg';
import SpacerVertical from '../../../assets/icons/icon-spacer-vertical.svg';
import Spinner from '../../../assets/icons/icon-spinner.svg';
import Star from '../../../assets/icons/icon-star.svg';
import StarFilled from '../../../assets/icons/icon-star-filled.svg';
import StrikeThrough from '../../../assets/icons/icon-strike-through.svg';
import Tableau from '../../../assets/icons/icon-tableau.svg';
import TrashIcon from '../../../assets/icons/icon-trash.svg';
import Underline from '../../../assets/icons/icon-underline.svg';
import VerticalEllipsis from '../../../assets/icons/icon-vertical-ellipsis.svg';
import Waypoint from '../../../assets/icons/icon-waypoint.svg';
import XMark from '../../../assets/icons/icon-x-mark.svg';

import { IconNames } from './IconTypes';

export const IconSvg = {
  [IconNames.addUser]: AddUser,
  [IconNames.alertFilled]: AlertFilled,
  [IconNames.arrowDown]: ArrowDown,
  [IconNames.arrowLeft]: ArrowLeft,
  [IconNames.arrowRight]: ArrowRight,
  [IconNames.arrowUp]: ArrowUp,
  [IconNames.avatar]: Avatar,
  [IconNames.bell]: Bell,
  [IconNames.bold]: Bold,
  [IconNames.bulletPoint]: BulletPoint,
  [IconNames.calendar]: Calendar,
  [IconNames.centerAlign]: CenterAlign,
  [IconNames.checkmark]: Checkmark,
  [IconNames.circleCheckFilled]: CircleCheckFilled,
  [IconNames.circleInfo]: CircleInfo,
  [IconNames.circleInfoFilled]: CircleInfoFilled,
  [IconNames.circleWaitingFilled]: CircleWaitingFilled,
  [IconNames.clock]: Clock,
  [IconNames.clockFilled]: ClockFilled,
  [IconNames.collapseAll]: CollapseAll,
  [IconNames.copy]: Copy,
  [IconNames.dollar]: Dollar,
  [IconNames.dollarFilled]: DollarFilled,
  [IconNames.download]: Download,
  [IconNames.expandAll]: ExpandAll,
  [IconNames.eye]: Eye,
  [IconNames.eyeHidden]: EyeHidden,
  [IconNames.filter]: Filter,
  [IconNames.filterFilled]: FilterFilled,
  [IconNames.handle]: Handle,
  [IconNames.heart]: Heart,
  [IconNames.heartFilled]: HeartFilled,
  [IconNames.italic]: Italic,
  [IconNames.leftAlign]: LeftAlign,
  [IconNames.mail]: Mail,
  [IconNames.menu]: Menu,
  [IconNames.pencil]: Pencil,
  [IconNames.placeholder]: Placeholder,
  [IconNames.popout]: PopoutIcon,
  [IconNames.plus]: PlusIcon,
  [IconNames.open]: OpenIcon,
  [IconNames.reset]: Reset,
  [IconNames.rightAlign]: RightAlign,
  [IconNames.settings]: Settings,
  [IconNames.search]: Search,
  [IconNames.share]: Share,
  [IconNames.sort]: Sort,
  [IconNames.sortAscending]: SortAscending,
  [IconNames.sortDescending]: SortDescending,
  [IconNames.spacerVertical]: SpacerVertical,
  [IconNames.spinner]: Spinner,
  [IconNames.star]: Star,
  [IconNames.starFilled]: StarFilled,
  [IconNames.strikeThrough]: StrikeThrough,
  [IconNames.tableau]: Tableau,
  [IconNames.trash]: TrashIcon,
  [IconNames.underline]: Underline,
  [IconNames.verticalEllipsis]: VerticalEllipsis,
  [IconNames.waypoint]: Waypoint,
  [IconNames.xMark]: XMark,
};

const IconWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-content: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Icon = ({ className, name }) => {
  const IconComponent = IconSvg[name];
  return (
    <IconWrapper
      className={AttrsHelper.formatClassname(
        'rd-icon',
        `icon-${name}`,
        className
      )}
      data-test={`${name}-icon`}
      width={18}
      height={18}
    >
      <IconComponent />
    </IconWrapper>
  );
};

Icon.propTypes = {
  className: string,
  name: oneOf(Object.values(IconNames)),
};

Icon.defaultProps = {
  className: null,
  name: IconNames.placeholder,
};
