import React from 'react';
import {
  Candidate,
  GroupedExperiences,
  PracticeArea,
  SubmissionCandidate,
} from '@axiom/validation';

import { BioPdf } from '../BiosPdf/BioPdf';
import { BioView } from '../BiosView/BioView';

export const TalentBios = ({
  candidate,
  groupedExperiences,
  practiceAreas,
  onNavigate = () => {},
  isAnonymous = false,
  download = false,
  clientUrl,
}: {
  candidate: Candidate & SubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  practiceAreas: PracticeArea[];
  onNavigate?: () => void;
  isAnonymous?: boolean;
  download?: boolean;
  clientUrl: string;
}): JSX.Element | null => {
  return download ? (
    <BioPdf
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      isAnonymous={isAnonymous}
      clientUrl={clientUrl}
    />
  ) : (
    <BioView
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      isAnonymous={isAnonymous}
      onNavigate={onNavigate}
    />
  );
};
