import React from 'react';
import {
  Candidate,
  GroupedExperiences,
  PracticeArea,
  SubmissionCandidate,
} from '@axiom/validation';

import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import { Banner } from '../../element/Banner/Banner';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Layout } from '../../layout/Layout/Layout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Flashy } from '../../element/Flashy/Flashy';

import { BioViewCandidate } from './BioViewCandidate';

export type BiosTypes = {
  candidate: Candidate & SubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  practiceAreas: PracticeArea[];
  isAnonymous?: boolean;
  onNavigate?: () => void;
};

export const BioView = ({
  candidate,
  groupedExperiences,
  practiceAreas,
  isAnonymous = false,
  onNavigate = () => {},
}: BiosTypes) => {
  return (
    <Layout name="BIO_VIEW" position="center">
      <LayoutItem>
        <Gutter vertical="24px">
          <Banner type="critical" impact="low">
            <ParagraphHeader as="span">
              <Flashy color="textDefault">
                Sharing this bio with a client?
              </Flashy>
            </ParagraphHeader>{' '}
            Click the download button below. Clients can’t open bio links.
          </Banner>
          <Gutter bottom="24px" />
          <Flashy backgroundColor="panelLight">
            <BioViewCandidate
              candidate={candidate}
              groupedExperiences={groupedExperiences}
              practiceAreas={practiceAreas}
              isAnonymous={isAnonymous}
              onNavigate={onNavigate}
            />
          </Flashy>
        </Gutter>
      </LayoutItem>
    </Layout>
  );
};
