import React from 'react';
import {
  Account,
  Candidate,
  GroupedExperiences,
  PracticeArea,
  Submission,
  SubmissionCandidate,
  Taxonomy,
} from '@axiom/validation';
import { SubmissionCandidateModel } from '@axiom/ui-models';

import { colors } from '../../../theme/axiom-theme';
import { CandidateHighlightExperienceSummary } from '../CandidateHighlightExperienceSummary/CandidateHighlightExperienceSummary';
import { CandidateHighlightExperienceDetails } from '../CandidateHighlightExperienceDetails/CandidateHighlightExperienceDetails';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Card } from '../../layout/Card/Card';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { AxiomLogo } from '../../element/AxiomLogo/AxiomLogo';
import { CandidateHighlightsUtil } from '../../../utils/candidate-highlights-util';
import { ClientEngagementsCandidateHeader } from '../ClientEngagementsCandidateHeader/ClientEngagementsCandidateHeader';
import { CardSection } from '../../layout/Card/CardSection';
import { CandidateDescription } from '../CandidateDescription/CandidateDescription';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { TalentExperiences } from '../TalentExperiences/TalentExperiences';
import { TalentEducation } from '../TalentEducation/TalentEducation';
import { TalentCertifications } from '../TalentCertifications/TalentCertifications';
import { TalentQualifications } from '../TalentQualifications/TalentQualifications';
import { SectionHeader } from '../../content/SectionHeader/SectionHeader';
import { TalentLanguages } from '../TalentLanguages/TalentLanguages';
import { PdfWantToTakeAction } from '../PdfWantToTakeAction/PdfWantToTakeAction';
import TransparentVectorBkg from '../../../assets/graphics/TransparentVectorTop.svg';

export const BioPdfCandidate = ({
  candidate,
  groupedExperiences,
  submission = null,
  accountId = null,
  practiceAreas,
  isAnonymous = false,
  clientUrl,
  taxonomy,
  removeTalentImage = false,
  removeLogo = false,
}: {
  practiceAreas: PracticeArea[];
  candidate: Candidate & SubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  submission?: Submission;
  accountId?: Account['id'];
  isAnonymous?: boolean;
  clientUrl: string;
  taxonomy?: Taxonomy;
  removeTalentImage?: boolean;
  removeLogo?: boolean;
}) => {
  const candidateDescription = new SubmissionCandidateModel(
    candidate
  ).calculatedDescription();

  const correctHighlightCalculation = taxonomy
    ? CandidateHighlightsUtil.calculateNumberOfHighlights(candidate, submission)
    : CandidateHighlightsUtil.calculateNumberOfSummaryHighlights(candidate);

  return (
    <>
      <Gutter bottom="32px">
        <Card name="TALENT_CONTAINER" shadow={null}>
          <CardSection
            background={{
              image: TransparentVectorBkg,
              color: colors?.controlInverse,
            }}
          >
            <Layout position="space-between middle">
              <LayoutItem position="left">
                <ClientEngagementsCandidateHeader
                  candidate={candidate}
                  isAnonymous={isAnonymous}
                  removeTalentImage={removeTalentImage}
                />
              </LayoutItem>
              {!removeLogo && (
                <LayoutItem position="right">
                  <AxiomLogo name="LOGO" height="47px" />
                </LayoutItem>
              )}
            </Layout>
          </CardSection>
          <CardSection divider>
            {candidateDescription && taxonomy && (
              <Gutter bottom="32px">
                <CandidateDescription
                  description={candidateDescription}
                  calculatedFirstName={candidate.calculatedFirstName}
                />
              </Gutter>
            )}
            {correctHighlightCalculation > 0 && (
              <>
                <Gutter bottom="32px">
                  <SmallHeader name="highlights-title-pdf">
                    Highlights
                  </SmallHeader>
                </Gutter>
                <CandidateHighlightExperienceSummary
                  candidate={candidate}
                  submission={submission}
                  condensed
                />

                <Gutter bottom="16px" />
                {taxonomy && (
                  <CandidateHighlightExperienceDetails
                    candidate={candidate}
                    practiceAreas={practiceAreas}
                    taxonomy={taxonomy}
                  />
                )}
              </>
            )}
          </CardSection>

          {groupedExperiences.length > 0 && (
            <>
              <CardSection divider>
                <SmallHeader name="EXPERIENCE_LABEL">Experience</SmallHeader>
              </CardSection>
              <TalentExperiences
                groupedExperiences={groupedExperiences}
                practiceAreas={practiceAreas}
              />
            </>
          )}

          {candidate?.degrees?.length > 0 && (
            <CardSection>
              <Gutter bottom="24px">
                <SmallHeader>Education</SmallHeader>
              </Gutter>
              <TalentEducation candidate={candidate} />
            </CardSection>
          )}
          {(candidate?.certifications?.length > 0 ||
            candidate?.languages?.length > 0) && (
            <CardSection divider>
              <Gutter bottom="24px">
                <SmallHeader>Accomplishments</SmallHeader>
              </Gutter>
              {candidate?.certifications?.length > 0 && (
                <>
                  <Gutter bottom="16px">
                    <TalentCertifications candidate={candidate} />
                  </Gutter>
                  <Gutter bottom="16px">
                    <TalentQualifications candidate={candidate} />
                  </Gutter>
                </>
              )}
              {candidate?.languages?.length > 0 && (
                <>
                  <Gutter bottom="12px">
                    <SectionHeader>Languages</SectionHeader>
                  </Gutter>
                  <TalentLanguages candidate={candidate} />
                </>
              )}
            </CardSection>
          )}
        </Card>
      </Gutter>
      {accountId && submission?.submissionId && (
        <Gutter horizontal="32px">
          <PdfWantToTakeAction
            candidate={candidate}
            accountId={accountId}
            submissionId={submission.submissionId}
            clientUrl={clientUrl}
          />
        </Gutter>
      )}
    </>
  );
};
