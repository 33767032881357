import orderBy from 'lodash/orderBy';
import { shape, string, bool } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';

import { CandidateOpportunityLegacyApi } from '../api/candidate-opportunities-api-legacy';
import GenericStore from '../classes/generic-store';
import { getSubmission, patchSubmission } from '../api/submissions';
import { ObjectUtil } from '../utils/object-util';
import { TalentActivitiesApi } from '../api/talentActivities';

import { OpportunityCandidatesStore } from './opportunity-candidates-store';
// eslint-disable-next-line import/no-cycle
import { EditSubmissionRateStore } from './edit-submission-rate-store';
import { OpportunityStore } from './opportunity-store';

const { CandidateStatuses } = CandidateOpportunitiesConst;

const SUBMISSION_CANDIDATE_STATUS_BLACKLIST = [
  CandidateStatuses.Removed,
  CandidateStatuses.TalentOptOut,
];

const SUBMISSION_CANDIDATE_STATUS_SET_RATE_BLACKLIST = [
  ...SUBMISSION_CANDIDATE_STATUS_BLACKLIST,
  CandidateStatuses.Engaged,
  CandidateStatuses.Completed,
];

class EditSubmissionStoreClass extends GenericStore {
  static transformSubmission(submission, opportunityCandidates) {
    return {
      ...submission,
      candidates: opportunityCandidates.filter(
        candidate =>
          !SUBMISSION_CANDIDATE_STATUS_BLACKLIST.includes(
            candidate.candidateStatus
          ) && candidate.position
      ),
    };
  }

  static resetCandidateRankings(candidates) {
    if (!candidates) {
      return [];
    }

    const rankedCandidates = candidates.filter(c => c.isActiveForSubmission);
    const unrankedCandidates = orderBy(
      candidates.filter(c => !c.isActiveForSubmission),
      'firstName'
    );
    rankedCandidates.forEach((c, index) => {
      if (c.isActiveForSubmission) {
        c.submissionRank = index;
      } else {
        c.submissionRank = null;
      }
    });

    return rankedCandidates.concat(unrankedCandidates);
  }

  static orderCandidates(candidates) {
    if (!candidates) {
      return [];
    }

    const rankedCandidates = orderBy(
      candidates.filter(c => c.isActiveForSubmission),
      'submissionRank'
    );
    const unrankedCandidates = orderBy(
      candidates.filter(c => !c.isActiveForSubmission),
      'firstName'
    );

    return rankedCandidates.concat(unrankedCandidates);
  }

  async load(submissionId, opportunityId, candidateId, showRateTab) {
    return this.setState(
      Promise.all([
        getSubmission(submissionId),
        OpportunityCandidatesStore.load(opportunityId),
      ]).then(([{ data: submission }, opportunityCandidates]) => {
        const transformedSubmission =
          EditSubmissionStoreClass.transformSubmission(
            submission,
            opportunityCandidates
          );
        const orderedCandidates = EditSubmissionStoreClass.orderCandidates(
          transformedSubmission.candidates
        );

        if (!candidateId && orderedCandidates.length) {
          candidateId = orderedCandidates[0].id;
        }

        if (candidateId) {
          EditSubmissionRateStore.load(
            submission,
            orderedCandidates.find(c => c.id === candidateId)
          );
        }

        const submissionData = {
          ...transformedSubmission,
          submissionId,
          opportunityId,
          selectedCandidateId: candidateId,
          showRateTab,
          candidates: orderedCandidates,
        };

        return {
          initialSubmission: ObjectUtil.clone(submissionData),
          ...submissionData,
        };
      })
    );
  }

  getDataShape() {
    return shape({
      accountName: string,
      currency: string,
      candidates: OpportunityCandidatesStore.getDataShape(),
      jobName: string,
      id: string,
      opportunityId: string,
      initialSubmission: shape({}),
      selectedCandidateId: string,
      showRateTab: bool,
      submissionLastUpdatedAt: string,
    });
  }

  isRateSettableForCandidate(opportunityCandidate, opportunity) {
    return (
      opportunityCandidate &&
      !SUBMISSION_CANDIDATE_STATUS_SET_RATE_BLACKLIST.includes(
        opportunityCandidate.candidateStatus
      ) &&
      opportunity &&
      opportunity.isFulfillmentActive
    );
  }

  transformSubmissionForPatch(submission) {
    if (!submission) {
      return null;
    }

    const request = {
      newSubmissionType: submission.newSubmissionType,
      candidates: submission.candidates.map(c => ({
        candidateId: c.id,
        highlightedExperiences: c.isActiveForSubmission
          ? c.experiences.map(exp => ({
              id: exp.id,
              experienceVisible: exp.experienceVisible,
              isHighlighted: exp.isHighlighted,
              submissionDescription: exp.submissionDescription,
            }))
          : [],
        isActiveForSubmission: c.isActiveForSubmission,
        submissionRank: c.submissionRank,
        submissionDescription: c.submissionDescription,
      })),
    };

    return request;
  }

  save(submission) {
    submission = {
      ...submission,
      candidates: EditSubmissionStoreClass.resetCandidateRankings(
        submission.candidates
      ),
    };

    return this.watchState(
      patchSubmission(
        submission.submissionId,
        this.transformSubmissionForPatch(submission)
      )
    ).then(() => {
      OpportunityStore.load(submission.opportunityId);
      this.load(submission.submissionId, submission.opportunityId);
      CandidateOpportunityLegacyApi.refreshAllCandidateOpportunities();
      TalentActivitiesApi.refreshAllActivities();
    });
  }
}

export const EditSubmissionStore = new EditSubmissionStoreClass();
