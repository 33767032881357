import Ajv from 'ajv';
import {
  Candidate,
  CandidatesConst,
  CandidateAggregationsConst,
  CandidateOccupationTypes,
  CandidateOccupationType,
  CountryCodesConst,
  CurrencyMap,
} from '@axiom/const';
import { z } from 'zod';
import {
  SchemaDate,
  SchemaEmail,
  SchemaTimestamp,
  SchemaYear,
} from '@axiom/types';

import { TimeZoneIanaSchema } from './time-zone';
import { axiomValidationOptions } from './options';
import { CertificationSchema } from './certification';
import { EducationSchema } from './education';
import { ExperienceSchema } from './experience';
import { TagSchema } from './tag';
import { CandidateLanguageSchema } from './candidate_language';
import { UserSchema } from './user';
import { HomeOfficeSchema } from './home_office';
import { arrayValidatorCreator } from './general';
import { getMaxFloatValue } from './utils';
import { BarredLocationSchema } from './barred_locations';
import { LawFirmSchema } from './law_firm';
import { PositionSchema } from './positions';
import { PracticeAreaWithParentSchema } from './practice_area_with_parent';
import { CandidateIndustrySchema } from './candidate_industry';
import { EventSchema } from './event';

const {
  FurthestCandidacyStatuses,
  EmploymentStatuses,
  EmployeeTypes,
  ProfileStatuses,
} = CandidatesConst;

const EmploymentStatusValues = Object.values(
  EmploymentStatuses
) as NonEmptyArray<string>;

const EmployeeTypeValues = Object.values(
  EmployeeTypes
) as NonEmptyArray<string>;

export const ProfileStatusValues = Object.values(
  ProfileStatuses
) as NonEmptyArray<string>;

const CandidateOccupationTypeValues = Object.values(
  CandidateOccupationTypes
) as NonEmptyArray<CandidateOccupationType>;

const CandidateCurrentTypeValues = Object.keys(
  CurrencyMap.codes
) as NonEmptyArray<CandidateOccupationType>;

const CountryCodeKeys = Object.keys(
  CountryCodesConst.CountryCodesAbbreviations
) as NonEmptyArray<string>;

export const CandidateSchema = z.object({
  activeWorkEmail: SchemaEmail.nullable(),
  address1: z.string().max(255).nullable(),
  address2: z.string().max(255).nullable(),
  addressCity: z.string().max(255).nullable(),
  addressCountry: z.string().max(255).nullable(),
  addressCountryCode: z.enum(CountryCodeKeys).nullable(),
  addressState: z.string().max(255).nullable(),
  addressZip: z.string().max(255).nullable(),
  availabilityNotes: z.string().nullable(),
  alphabeticallySmallestEngagementAccountName: z.string().nullable(),
  alphabeticallySmallestEngagementEndDate: SchemaDate.nullable(),
  alphabeticallySmallestEngagementEndDateStatus: z.string().nullable(),
  availabilityPreferencesUpdatedAt: SchemaTimestamp.nullable(),
  availabilityPreferencesUpdatedBy: z.string().uuid().nullable(),
  availabilityPreferencesUpdatedByType: z.string().nullable(),
  axiomEmployeeType: z.string().max(255).nullable(),
  barredLocations: z.array(BarredLocationSchema).nullish(),
  baseAnnualSalary: z.number().nonnegative().nullable(),
  bonusPercentage: z.number().nonnegative().nullable(),
  bullhornId: z.string().max(255).nullable(),
  calculatedDesiredWeeklyHours: z.number().positive().nullable(),
  calculatedDisplayName: z.string().nullable(),
  calculatedFirstName: z.string().nullable(),
  calculatedFullName: z.string().nullable(),
  calculatedLastName: z.string().nullable(),
  calendar: z
    .object({
      id: z.string().uuid(),
      nylasCalendarId: z.string().uuid().nullable(),
      timezone: TimeZoneIanaSchema,
    })
    .nullable(),
  candidateHeader: z.string().max(255).nullable(),
  candidateHeaderUpdatedAt: SchemaTimestamp.nullable(),
  candidateSummary: z.string().max(500).nullable(),
  candidateSummaryApprovedAt: SchemaTimestamp.nullable(),
  candidateSummaryApprovedBy: z.string().uuid().nullable(),
  candidateSummaryUpdatedAt: SchemaTimestamp.nullable(),
  certifications: z.array(CertificationSchema).nullish(),
  compensation: z.string().nullable(),
  counselWorkPreference: z.string().nullable(),
  createdAt: SchemaTimestamp.nullable(),
  currentAvailabilityUpdatedAt: SchemaDate.nullable(),
  daysDesiredRemote: z.number().nonnegative().int().default(5).nullable(),
  daysRequiredRemote: z.number().nonnegative().int().default(0).nullable(),
  daysSinceAvailabilityUpdate: z
    .number()
    .positive()
    .int()
    .default(0)
    .nullable(),
  daysSinceLastCheckIn: z.number().positive().int().default(0).nullable(),
  daysSinceStatusChange: z.number().positive().int().default(0).nullable(),
  daysWillingToCommute: z.number().nonnegative().int().default(5).nullable(),
  degrees: z.array(EducationSchema).nullish(),
  desiredCommute: z.string().max(255).nullable(),
  desiredEngagementSpecificCompensation: z.string().max(255).nullable(),
  desiredHoursUpdatedAt: SchemaDate.nullable(),
  desiredWeeklyMaxHours: z.number().nonnegative().nullable(),
  displayBillingRate: z.string().max(255).nullable(),
  displayFirstName: z.string().max(255).nullable(),
  displayHighHourlyRate: z.string().nullable(),
  displayLastName: z.string().max(255).nullable(),
  displayLowHourlyRate: z.string().nullable(),
  displayWeeklyRate: z.string().nullable(),
  employeeType: z.enum(EmployeeTypeValues).nullable(),
  employmentStatus: z.enum(EmploymentStatusValues).nullable(),
  experiences: z.array(ExperienceSchema).nullish(),
  firstName: z.string().max(255).nullable(),
  flaggedAt: SchemaTimestamp.nullable(),
  flaggedBy: z.string().uuid().nullable(),
  furthestCandidacyStatus: z.enum(FurthestCandidacyStatuses).nullable(),
  furthestCandidacyStatusUpdatedAt: SchemaTimestamp.nullable(),
  hasRate: z.boolean().nullable(),
  homeOfficeId: z.string().uuid().nullable(),
  homeOffice: HomeOfficeSchema.nullable(),
  homePhone: z.string().max(255).nullable(),
  hourlyCompensation: z.string().nullable(),
  id: z.string().uuid(),
  industries: z.array(CandidateIndustrySchema).nullish(),
  integrationId: z.string().max(255).nullable(),
  isAccessibleToWorkFeed: z.boolean().default(false),
  isAvailableToConsult: z.boolean().default(false),
  isFlagged: z.boolean().default(false),
  isOpenToAdHoc: z.boolean().default(true),
  isOpenToCommute: z.boolean().default(true),
  isOpenToFullTime: z.boolean().default(true),
  isOpenToMultipleClients: z.boolean().default(true),
  isOpenToOtherWork: z.boolean().default(true),
  isOpenToPartTime: z.boolean().default(true),
  isOpenToRemote: z.boolean().default(true),
  isOptInWorkFeedEmail: z.boolean().default(true).nullable(),
  oppAlertsMessageDismissed: z.boolean().default(false),
  isProfileShared: z.boolean().default(false),
  isSpotlightedLawyer: z.boolean().default(true),
  searchableByClient: z.boolean().default(true),
  isViewOnly: z.boolean().default(true),
  isWorkFeedAccessible: z.boolean().default(true),
  languages: z.array(CandidateLanguageSchema).nullish(),
  lastCheckIn: EventSchema.nullable(),
  lastName: z.string().max(255).nullable(),
  lastUpdatedBy: z.string().uuid().nullable(),
  latestEngagementAccountName: z.string().max(255).nullable(),
  latestEngagementEndDate: SchemaDate.nullable(),
  lawDegreeSchools: z.array(EducationSchema).nullish(),
  lawFirms: z.array(LawFirmSchema).nullish(),
  lawSchool: z.string().max(255).nullable(),
  lawSchoolId: z.string().uuid().nullable(),
  maxDailyHours: z.number().nonnegative().nullable(),
  maxHours: z.number().nonnegative().nullable(),
  maxMinutesCommuteOneWay: z.number().nonnegative().int().nullable(),
  middleName: z.string().max(255).nullable(),
  minWeeklyHoursPerPosition: z.number().nonnegative().int().default(0),
  currencyType: z.enum(CandidateCurrentTypeValues).nullable(),
  compHourlyPremium: z.number().nonnegative().nullable(),
  compHourly40hr: z.number().nonnegative().nullable(),
  hoursPerDay: z.number().nonnegative().nullable(),
  mobilePhone: z.string().max(255).nullable(),
  noticePeriod: z.string().max(255).nullable(),
  occupationType: z.enum(CandidateOccupationTypeValues).nullable(),
  openToAdviceAndCounsel: z.boolean().default(false),
  openToSecondment: z.boolean().default(false),
  workStylePreferencesReviewedAt: SchemaTimestamp.nullable(),
  workStylePreferencesReviewedBy: z.string().uuid().nullable(),
  programsPreferencesReviewedAt: SchemaTimestamp.nullable(),
  programsPreferencesReviewedBy: z.string().uuid().nullable(),
  profileVisibilityReviewedAt: SchemaTimestamp.nullable(),
  profileVisibilityPreferencesReviewedBy: z.string().uuid().nullable(),
  openToVariableHourlyProjects: z.boolean().default(false),
  openToLargeProjects: z.boolean().default(false),
  openToPermanentPlacement: z.boolean().default(false),
  ownerUserId: z.string().uuid().nullable(),
  ownerUser: UserSchema.nullable(),
  partTimeHoursFri: z.number().nonnegative().int().nullable(),
  partTimeHoursMon: z.number().nonnegative().int().nullable(),
  partTimeHoursThu: z.number().nonnegative().int().nullable(),
  partTimeHoursTue: z.number().nonnegative().int().nullable(),
  partTimeHoursWed: z.number().nonnegative().int().nullable(),
  personalEmail: SchemaEmail.nullable(),
  position: PositionSchema.nullish(),
  practiceAreaId: z.string().uuid().nullable(),
  practiceArea: PracticeAreaWithParentSchema.nullish(),
  practiceStartYear: SchemaYear.nullable(),
  preferredContactMethod: z.string().max(255).nullable(),
  previousProfileStatus: z.enum(ProfileStatusValues).nullable(),
  profileImageKey: z.string().max(255).nullable(),
  profileImageName: z.string().max(255).nullable(),
  profilePercentComplete: z.number().int().default(0),
  profileStatus: z.enum(ProfileStatusValues).nullable(),
  profileStatusLastUpdatedBy: z.string().uuid().nullable(),
  profileStatusUpdatedAt: SchemaTimestamp.nullable(),
  profileStatusUpdatedByUser: UserSchema.nullish(),
  proposedHourlyRate: z.number().nonnegative().nullable(),
  publicCandidateSummary: z.string().nullable(),
  publicRefId: z.number().int().positive(),
  resume: z.string().nullable(),
  isInterviewAvailabilityOutdated: z.boolean().nullable(),
  soonestEngagementAccountName: z.string().max(255).nullable(),
  soonestEngagementEndDate: SchemaDate.nullable(),
  soonestEngagementEndDateStatus: z.string().max(255).nullable(),
  tags: z.array(TagSchema).nullish(),
  updatedAt: SchemaTimestamp.nullable(),
  user: UserSchema.nullish(),
  weeklyAvailability: z.number().nonnegative().nullable(),
  weeklyAvailabilityLabel: z.string().nullable(),
  workEmail: SchemaEmail.nullable(),
  yearsOfExperience: z.number().positive().int().nullable(),
});

export const CandidateSummarySchema = z.object({
  candidateSummary: z.string().max(500).trim().min(1, { message: 'Required' }),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const ajvNoCoerce = new Ajv({
  ...axiomValidationOptions(),
});

// VALIDATIONS
const candidateCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    firstName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    middleName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    lastName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    mobilePhone: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    homePhone: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    address1: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    address2: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressCity: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressState: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressZip: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressCountry: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    addressCountryCode: {
      type: ['string', 'null'],
      enum: CountryCodeKeys.concat([null]),
    },
    barredLocations: {
      type: ['array', 'null'],
      items: { type: 'string', format: 'uuid' },
    },
    personalEmail: {
      type: ['string', 'null'],
      format: 'email',
    },
    workEmail: {
      type: ['string', 'null'],
      format: 'email',
    },
    compensation: {
      type: ['number', 'null'],
    },
    hourlyCompensation: {
      type: ['number', 'null'],
      maximum: 999999.99,
    },
    practiceAreaId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    employeeType: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    axiomEmployeeType: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    occupationType: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    lawSchool: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    practiceStartYear: {
      type: ['integer', 'null'],
    },
    homeOfficeId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    integrationId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    resume: {
      type: ['string', 'null'],
      maxLength: 1000000,
    },
    ownerUserId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    availabilityNotes: {
      type: ['string', 'null'],
    },
    noticePeriod: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    profileStatus: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    employmentStatus: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    desiredEngagementSpecificCompensation: {
      type: ['string', 'null'],
      enum: ['Yes', 'No'],
      maxLength: 255,
    },
    desiredCommute: {
      type: ['string', 'null'],
      enum: ['Yes', 'No'],
      maxLength: 255,
    },
    preferredContactMethod: {
      type: ['string', 'null'],
      enum: ['Personal', 'Axiom', 'Both'],
    },
    isOpenToFullTime: {
      type: ['boolean', 'null'],
    },
    isOpenToPartTime: {
      type: ['boolean', 'null'],
    },
    isOpenToAdHoc: {
      type: ['boolean', 'null'],
    },
    isOpenToRemote: {
      type: ['boolean', 'null'],
    },
    isOpenToCommute: {
      type: ['boolean', 'null'],
    },
    isOptInWorkFeedEmail: {
      type: ['boolean', 'null'],
    },
    oppAlertsMessageDismissed: {
      type: 'boolean',
    },
    partTimeHoursMon: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 7,
    },
    partTimeHoursTue: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 7,
    },
    partTimeHoursWed: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 7,
    },
    partTimeHoursThu: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 7,
    },
    partTimeHoursFri: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 7,
    },
    daysRequiredRemote: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 5,
    },
    daysDesiredRemote: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 5,
    },
    daysWillingToCommute: {
      type: ['integer', 'null'],
      minimum: 1,
      maximum: 5,
    },
    maxMinutesCommuteOneWay: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 120,
    },
    isFlagged: {
      type: ['boolean'],
    },
    displayFirstName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    displayLastName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    [Candidate.AVAILABILITY_PREFERENCES_UPDATED_AT]: {
      type: ['string', 'null'],
      maxLength: 255,
      format: 'date-time',
    },
    isProfileShared: {
      type: ['boolean'],
    },
    isSpotlightedLawyer: {
      type: ['boolean', 'null'],
    },
    searchableByClient: {
      type: ['boolean', 'null'],
    },
    candidateHeader: {
      type: ['string', 'null'],
      maxLength: 40,
    },
    candidateSummary: {
      type: ['string', 'null'],
      maxLength: 500,
    },
    candidateSummaryApprovedBy: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    isOpenToMultipleClients: {
      type: ['boolean', 'null'],
    },
    counselWorkPreference: {
      type: ['string', 'null'],
      enum: CandidatesConst.CounselWorkPreferences,
    },
    isOpenToOtherWork: {
      type: ['boolean', 'null'],
    },
    desiredWeeklyMaxHours: {
      type: ['integer', 'null'],
      minimum: 0,
      maximum: 80,
    },
    minWeeklyHoursPerPosition: {
      type: ['integer'],
      minimum: 0,
    },
    currencyType: {
      type: ['string', 'null'],
      enum: Object.keys(CurrencyMap.codes).concat([null]),
    },
    compHourlyPremium: {
      type: ['number', 'null'],
      minimum: 0,
      maximum: getMaxFloatValue(18, 4),
    },
    compHourly40hr: {
      type: ['number', 'null'],
      minimum: 0,
      maximum: getMaxFloatValue(18, 4),
    },
    hoursPerDay: {
      type: ['number', 'null'],
      minimum: 0,
      maximum: getMaxFloatValue(18, 4),
    },
    openToAdviceAndCounsel: {
      type: ['boolean'],
    },
    openToSecondment: {
      type: ['boolean'],
    },
    openToVariableHourlyProjects: {
      type: ['boolean'],
    },
    openToLargeProjects: {
      type: ['boolean'],
    },
    openToPermanentPlacement: {
      type: ['boolean'],
    },
    workStylePreferencesReviewedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
    programsPreferencesReviewedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
    profileVisibilityReviewedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
  },
};

const createCandidateValidation = {
  ...candidateCommonValidation,
  required: ['firstName', 'lastName'],
};

// used by PUT endpoint upsert for integration through boomi
const candidateUpsertValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    ...candidateCommonValidation.properties,
    // maxHours only comes through boomi
    maxHours: {
      type: 'number',
      maximum: Number.MAX_SAFE_INTEGER,
    },
    // rates only comes through boomi
    displayLowHourlyRate: {
      type: ['number', 'null'],
      maximum: 999999.99,
    },
    displayHighHourlyRate: {
      type: ['number', 'null'],
      maximum: 999999.99,
    },
    displayWeeklyRate: {
      type: ['number', 'null'],
      maximum: 999999.99,
    },
    // boomi should only send us Insourcing types
    axiomEmployeeType: {
      type: ['string'],
      const: 'Insourcing',
    },
    // boomi sends barred location names
    barredLocations: {
      type: ['array', 'null'],
      items: { type: 'string', minLength: 1, maxLength: 255 },
    },
    // boomi sends practice area bullhorn id for the following
    practiceAreaId: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    // boomi sends home office name for the following
    homeOfficeId: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    // boomi sends user integration id for the following
    ownerUserId: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    integrationId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    // boomi sends law school name for the following
    lawSchoolId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    baseAnnualSalary: {
      type: ['number', 'null'],
      maximum: getMaxFloatValue(18, 2),
    },
    bonusPercentage: {
      type: ['number', 'null'],
      maximum: getMaxFloatValue(18, 2),
    },
    loginEmail: {
      type: ['string', 'null'],
      format: 'email',
    },
  },
};

const candidateFilterValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    id: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    addressCountryCode: {
      type: ['string', 'null'],
      enum: CountryCodeKeys.concat([null]),
    },
    compensation: {
      type: 'object',
      additionalProperties: false,
      properties: {
        start: {
          type: 'number',
          maximum: Number.MAX_SAFE_INTEGER,
        },
        end: {
          type: 'number',
          maximum: Number.MAX_SAFE_INTEGER,
        },
        includeEmptyValues: {
          type: 'boolean',
        },
      },
    },
    employeeType: {
      type: 'array',
      items: {
        type: ['string', 'null'],
        enum: [...Object.values(CandidatesConst.EmployeeTypes), null],
      },
    },
    weeklyAvailability: {
      type: 'object',
      additionalProperties: false,
      properties: {
        start: {
          type: 'number',
          maximum: Number.MAX_SAFE_INTEGER,
        },
        end: {
          type: 'number',
          maximum: Number.MAX_SAFE_INTEGER,
        },
      },
    },
    homeOfficeId: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    languages: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    ownerUserId: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    practiceAreaId: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    profileStatus: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    axiomEmployeeType: {
      type: 'array',
      items: { type: 'string' },
    },
    integrationId: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    yearsOfExperience: {
      type: 'object',
      additionalProperties: false,
      properties: {
        start: {
          type: 'integer',
        },
        end: {
          type: 'integer',
        },
        includeEmptyValues: {
          type: 'boolean',
        },
      },
    },
    barredLocations: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    soonestEngagementEndDate: {
      type: 'object',
      additionalProperties: false,
      properties: {
        end: {
          type: 'string',
          format: 'date',
        },
        start: {
          type: 'string',
          format: 'date',
        },
      },
    },
    occupationType: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    isOpenToFullTime: {
      type: 'object',
      additionalProperties: false,
      properties: {
        value: {
          type: 'boolean',
        },
        includeEmptyValues: {
          type: 'boolean',
        },
      },
    },
    isOpenToPartTime: {
      type: 'object',
      additionalProperties: false,
      properties: {
        value: {
          type: 'boolean',
        },
        includeEmptyValues: {
          type: 'boolean',
        },
      },
    },
    isOpenToAdHoc: {
      type: 'object',
      additionalProperties: false,
      properties: {
        value: {
          type: 'boolean',
        },
        includeEmptyValues: {
          type: 'boolean',
        },
      },
    },
    isOpenToCommute: {
      type: 'object',
      additionalProperties: false,
      properties: {
        value: {
          type: 'boolean',
        },
        includeEmptyValues: {
          type: 'boolean',
        },
      },
    },
    isOpenToRemote: {
      type: 'object',
      additionalProperties: false,
      properties: {
        value: {
          type: 'boolean',
        },
        includeEmptyValues: {
          type: 'boolean',
        },
      },
    },
    'experiences.tags': {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    industries: {
      type: 'array',
      items: { type: 'string', format: 'uuid' },
    },
  },
  industries: {
    type: 'array',
    items: { type: 'string', format: 'uuid' },
  },
  similarTo: {
    type: 'array',
    items: {
      type: 'string',
      format: 'uuid',
    },
  },
};

const candidateBenchFilterValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    profileStatus: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
};

const candidateBenchTypeValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: ['team', 'owner', 'collaborator'],
    },
  },
};

const candidateSortTermValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    sort: {
      type: 'string',
      enum: Candidate.SORT_COLUMNS,
    },
  },
};

const candidateExternalSearchFilterValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    addressCountryCode: {
      type: 'array',
      items: {
        type: ['string', 'null'],
        enum: CountryCodeKeys.concat([null]),
      },
    },
    barredLocations: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    industries: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    occupationType: {
      type: 'array',
      items: { type: 'string' },
    },
    practiceAreaId: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    profession: {
      type: 'array',
      items: { type: 'string' },
    },
    state: {
      type: 'array',
      items: { type: 'string' },
    },
    weeklyAvailability: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    weeklyAvailabilityLabel: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    yearsOfExperience: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        properties: {
          start: {
            type: 'integer',
          },
          end: {
            type: 'integer',
          },
          includeEmptyValues: {
            type: 'boolean',
          },
        },
      },
    },
  },
  allOf: [
    // if profession is present, occupationType cannot be
    {
      if: {
        required: ['profession'],
      },
      then: {
        properties: {
          occupationType: {
            maxItems: 0,
          },
        },
      },
    },
  ],
};

const candidateStatusFilterValidation = {
  type: 'array',
  items: {
    type: 'string',
  },
};

const candidatePageSizeValidation = {
  type: 'number',
  maximum: 20,
  minimum: 1,
  name: 'pageSize',
};

const candidatePreferencesValidation = {
  // alternative to additionalProperties: false in combination with allOf.
  // See https://stackoverflow.com/questions/66262319/how-do-i-use-an-extended-definition-and-not-allow-additional-properties-in-a-way
  propertyNames: {
    enum: [
      'isOpenToFullTime',
      'isOpenToPartTime',
      'isOpenToAdHoc',
      'partTimeHoursMon',
      'partTimeHoursTue',
      'partTimeHoursWed',
      'partTimeHoursThu',
      'partTimeHoursFri',
      'isOpenToRemote',
      'daysDesiredRemote',
      'daysRequiredRemote',
      'isOpenToCommute',
      'daysWillingToCommute',
      'maxMinutesCommuteOneWay',
    ],
  },
  allOf: [
    {
      if: {
        required: ['isOpenToFullTime'],
        properties: { isOpenToFullTime: { type: 'boolean', enum: [true] } },
      },
      then: {
        properties: {
          isOpenToPartTime: { enum: [true, false] },
          isOpenToAdHoc: { enum: [true, false] },
          partTimeHoursMon: { enum: [null] } as { enum: Array<null> },
          partTimeHoursTue: { enum: [null] } as { enum: Array<null> },
          partTimeHoursWed: { enum: [null] } as { enum: Array<null> },
          partTimeHoursThu: { enum: [null] } as { enum: Array<null> },
          partTimeHoursFri: { enum: [null] } as { enum: Array<null> },
        },
        required: [
          'isOpenToPartTime',
          'isOpenToAdHoc',
          'partTimeHoursMon',
          'partTimeHoursTue',
          'partTimeHoursWed',
          'partTimeHoursThu',
          'partTimeHoursFri',
        ],
      },
      else: {
        if: {
          required: ['isOpenToFullTime'],
          properties: { isOpenToFullTime: { type: 'boolean', enum: [false] } },
        },
        then: {
          properties: {
            isOpenToPartTime: { enum: [true, false, null] },
            isOpenToAdHoc: { enum: [null] } as { enum: Array<null> },
            partTimeHoursMon: { enum: [0, 1, 2, 3, 4, 5, 6, 7] },
            partTimeHoursTue: { enum: [0, 1, 2, 3, 4, 5, 6, 7] },
            partTimeHoursWed: { enum: [0, 1, 2, 3, 4, 5, 6, 7] },
            partTimeHoursThu: { enum: [0, 1, 2, 3, 4, 5, 6, 7] },
            partTimeHoursFri: { enum: [0, 1, 2, 3, 4, 5, 6, 7] },
          },
          required: [
            'isOpenToPartTime',
            'isOpenToAdHoc',
            'partTimeHoursMon',
            'partTimeHoursTue',
            'partTimeHoursWed',
            'partTimeHoursThu',
            'partTimeHoursFri',
          ],
        },
      },
    },
    {
      if: {
        required: ['isOpenToRemote'],
        properties: { isOpenToRemote: { type: 'boolean', enum: [true] } },
      },
      then: {
        properties: {
          daysDesiredRemote: { enum: [0, 1, 2, 3, 4, 5] },
          daysRequiredRemote: { enum: [0, 1, 2, 3, 4, 5] },
        },
        required: ['daysDesiredRemote', 'daysRequiredRemote'],
      },
      else: {
        if: {
          properties: { isOpenToRemote: { type: 'boolean', enum: [false] } },
          required: ['isOpenToRemote'],
        },
        then: {
          properties: {
            daysDesiredRemote: { enum: [null] } as { enum: Array<null> },
            daysRequiredRemote: { enum: [null] } as { enum: Array<null> },
          },
          required: ['daysDesiredRemote', 'daysRequiredRemote'],
        },
      },
    },
    {
      if: {
        required: ['isOpenToCommute'],
        properties: { isOpenToCommute: { type: 'boolean', enum: [true] } },
      },
      then: {
        properties: {
          daysWillingToCommute: { enum: [1, 2, 3, 4, 5] },
          maxMinutesCommuteOneWay: { enum: [0, 30, 60, 120] },
        },
        required: ['daysWillingToCommute', 'maxMinutesCommuteOneWay'],
      },
      else: {
        if: {
          required: ['isOpenToCommute'],
          properties: { isOpenToCommute: { type: 'boolean', enum: [false] } },
        },
        then: {
          properties: {
            daysWillingToCommute: { enum: [null] } as { enum: Array<null> },
            maxMinutesCommuteOneWay: { enum: [null] } as { enum: Array<null> },
          },
          required: ['daysWillingToCommute', 'maxMinutesCommuteOneWay'],
        },
      },
    },
  ],
};

const candidateStatisticsQueryValidation = {
  ...candidateExternalSearchFilterValidation,
  properties: {
    ...candidateExternalSearchFilterValidation.properties,
    profilePercentComplete: { type: 'number', minimum: 0, maximum: 100 },
    isSpotlightedLawyer: { type: 'boolean' },
  },
};

// VALIDATORS
export const createCandidateValidator = ajv.compile(createCandidateValidation);
export const createCandidateArrayValidator = arrayValidatorCreator(
  createCandidateValidation
);
export const upsertCandidateValidatorCreator = ({
  ajvInstance = ajv,
  additionalOpts = {},
} = {}) =>
  ajvInstance.compile({
    ...candidateUpsertValidation,
    ...additionalOpts,
    anyOf: Object.keys(candidateUpsertValidation.properties).map(key => ({
      required: [key],
    })),
  });

export const upsertCandidateValidator = upsertCandidateValidatorCreator();

// ONLY CONDITIONALLY USE THIS - for boomi endpoints only
export const upsertCandidateAdditionalPropertiesValidator =
  upsertCandidateValidatorCreator({
    ajvInstance: new Ajv({
      ...axiomValidationOptions(),
      removeAdditional: true,
    }),
    additionalOpts: {
      additionalProperties: false,
    },
  });

export const candidateFilterValidator = ajv.compile(candidateFilterValidation);

export const candidateBenchFilterValidator = ajv.compile(
  candidateBenchFilterValidation
);

export const candidateBenchTypeValidator = ajv.compile(
  candidateBenchTypeValidation
);

export const candidateSortTermValidator = ajvNoCoerce.compile(
  candidateSortTermValidation
);

export const candidateExternalSearchFilterValidator = ajv.compile(
  candidateExternalSearchFilterValidation
);

export const candidatePageSizeValidator = ajvNoCoerce.compile(
  candidatePageSizeValidation
);

export const candidateStatusFilterValidator = ajv.compile(
  candidateStatusFilterValidation
);

export const updateCandidatePreferencesValidator = ajv.compile({
  ...candidatePreferencesValidation,
});

const setEnums = [
  ...Object.keys(candidateCommonValidation.properties),
  ...candidatePreferencesValidation.propertyNames.enum,
];
export const updateCandidateValidator = ajv.compile({
  properties: candidateCommonValidation.properties,
  propertyNames: {
    enum: [...new Set(setEnums)],
  },
  allOf: candidatePreferencesValidation.allOf,
  anyOf: Object.keys(candidateCommonValidation.properties).map(key => ({
    required: [key],
  })),
});

export const candidateStatisticsQueryValidator = ajv.compile(
  candidateStatisticsQueryValidation
);

export const candidateAggregationsValidation = new Ajv(
  axiomValidationOptions()
).compile({
  type: 'array',
  items: {
    type: 'object',
    properties: {
      size: { type: 'number' },
      filterName: {
        type: 'string',
        enum: Object.values(CandidateAggregationsConst.CANDIDATE_FILTERS),
      },
    },
    required: ['filterName'],
  },
});
