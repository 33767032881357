import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func, arrayOf, shape } from 'prop-types';
import { CandidateOpportunitiesConst, ClientRequestConst } from '@axiom/const';
import { CandidateOpportunityUtils } from '@axiom/utils';
import {
  Badge,
  Button,
  CondensedMedium,
  DarkTheme,
  Gutter,
  Icon,
  IconButton,
  Layout,
  LayoutItem,
  ReadonlyTextarea,
} from '@axiom/ui';

import UnorderedList from '../UnorderedList/UnorderedList';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import AutoWarmingModal from '../AutoWarmingModal/AutoWarmingModal';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { AutoCoolingModal } from '../AutoCoolingModal/AutoCoolingModal';
import { talentShape } from '../../models/talent';
import { CpqMarginIndicator } from '../CpqMarginIndicator/CpqMarginIndicator';
import { DateUtil } from '../../utils/date-util';
import { NumberUtil } from '../../utils/number-util';
import { opportunityShape } from '../../models/opportunities';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { EditAutoWarmingModalStore } from '../../stores/edit-auto-warming-modal-store';
import { FormAutoCoolingModalStore } from '../../stores/form-auto-cooling-modal-store';
import { CandidateUtil } from '../../utils/candidate-util';
import { CandidateCalendarMenu } from '../CandidateCalendarMenu/CandidateCalendarMenu';

import {
  OTCardWrapper,
  OTCardHeader,
  OTCardHeaderLeft,
  OTCardHeaderLeftName,
  OTCardHeaderRight,
  OTCardHeaderRightStatus,
  OTCardHeaderRightContextMenu,
  OTCardBody,
  OTCardBodyLeft,
  OTCardBodyRight,
  OTCardBodyItemCol,
  OTCardRateWrapper,
  OTCardBodyFull,
  OTCardBodyLabel,
} from './OpportunityTalentCardStyles';

const { AllStatusLabels, CandidateStatuses, OppCandMlRecStatuses } =
  CandidateOpportunitiesConst;
const { AfcLossReasonLabels, AfcLossReasonMessage } = ClientRequestConst;

function OpportunityTalentCard({
  candidate: {
    candidateStatus,
    candidateStatusUpdatedAt,
    previousCandidateStatus,
    clientRequest,
  },
  opportunity,
  candidate,
  opportunityCandidates,
  renderContextMenu,
  autoWarmingModalState,
  roleFitDescriptions,
  user,
}) {
  const oppCandidate = opportunityCandidates.find(c => c.id === candidate.id);
  const [showMore, setShowMore] = useState(false);
  const handleClickShowMore = () => setShowMore(!showMore);

  if (!oppCandidate) {
    return null;
  }
  const clientRequestOverrides = {};

  if (
    candidateStatus === CandidateStatuses.Rejected &&
    clientRequest?.candidateOpportunityStatus === CandidateStatuses.Rejected
  ) {
    clientRequestOverrides.passReasons = clientRequest.rejectionLossCodes
      .map(lc => AfcLossReasonLabels?.[lc])
      .filter(lc => lc);
    clientRequestOverrides.passMessages = clientRequest.rejectionLossCodes
      .map(lc => AfcLossReasonMessage?.[lc])
      .filter(lc => lc);
  }

  const getRate = () => {
    if (CandidateOpportunityUtils.hasSubmittedRate(oppCandidate)) {
      return NumberUtil.formatCurrency(
        oppCandidate.proposedHourlyRate,
        opportunity.currency
      );
    }
    return 'Not Set';
  };

  const getStatus = status => AllStatusLabels[status];

  const currentStatus = getStatus(candidateStatus);

  const roleFitDescription = roleFitDescriptions[candidate.id];

  return (
    <>
      <OTCardWrapper data-test="TALENT_CARD" data-value={candidate.id}>
        <DarkTheme>
          <OTCardHeader status={candidateStatus}>
            <OTCardHeaderLeft>
              {!opportunity.isClosed &&
                [
                  CandidateStatuses.Interested,
                  OppCandMlRecStatuses.AiSuggested,
                ].includes(candidateStatus) && (
                  <Gutter top="8px" left="8px">
                    <Layout position="top">
                      <Badge name="new-talent-badge" background="orange">
                        New
                      </Badge>
                    </Layout>
                  </Gutter>
                )}
              <OTCardHeaderLeftName>
                <UnorderedList
                  title="Talent Name"
                  values={
                    <>
                      {candidate.calculatedDisplayName}
                      <IconButton
                        inverse
                        icon="popout"
                        pattern="secondary"
                        variation="minimal"
                        toTab={`/talent-detail/${candidate.id}`}
                        title={`Open "${candidate.calculatedDisplayName}" in new tab`}
                      />
                    </>
                  }
                  candidateStatus={candidateStatus}
                />
              </OTCardHeaderLeftName>
            </OTCardHeaderLeft>
            <OTCardHeaderRight>
              <OTCardHeaderRightStatus>
                <UnorderedList
                  candidateStatus={candidateStatus}
                  title="Status"
                  dataValue={currentStatus}
                  values={
                    <Layout horizontalGutter="8px">
                      <LayoutItem position="left">{currentStatus}</LayoutItem>
                      {CandidateUtil.isSubmittedFromWorkFeed(
                        candidate,
                        opportunity
                      ) && (
                        <LayoutItem position="right">
                          <Badge
                            name="self-submit-badge"
                            background="light blue"
                          >
                            Self-Submit
                          </Badge>
                        </LayoutItem>
                      )}
                    </Layout>
                  }
                />
              </OTCardHeaderRightStatus>
              <OTCardHeaderRightContextMenu>
                <CandidateCalendarMenu
                  opportunityId={opportunity.id}
                  user={user}
                  candidate={candidate}
                  icon={
                    <IconButton
                      icon="calendar"
                      inverse
                      variation="minimal"
                      pattern="secondary"
                    />
                  }
                />
                {renderContextMenu()}
              </OTCardHeaderRightContextMenu>
            </OTCardHeaderRight>
          </OTCardHeader>
        </DarkTheme>
        {clientRequestOverrides?.passReasons?.length > 0 && (
          <OTCardBody>
            <UnorderedList
              title="Client Pass Reasons"
              values={clientRequestOverrides?.passReasons}
            />
          </OTCardBody>
        )}
        <OTCardBody>
          {oppCandidate.openToLowerEsc && (
            <OTCardBodyFull>
              <Gutter top="6px" bottom="8px">
                <Layout horizontalGutter="8px">
                  <Icon name="circle-check-filled" />
                  <CondensedMedium>
                    OK with lower ESC
                    {oppCandidate.openToLowerEscCompensationPercentage &&
                      ` (${oppCandidate.openToLowerEscCompensationPercentage}%)`}
                  </CondensedMedium>
                </Layout>
              </Gutter>
            </OTCardBodyFull>
          )}
          <OTCardBodyLeft>
            <OTCardBodyItemCol>
              <UnorderedList
                title="Previous Status"
                values={getStatus(previousCandidateStatus)}
              />
            </OTCardBodyItemCol>
            <OTCardBodyItemCol>
              {CandidateOpportunityUtils.hasSubmittedRate(oppCandidate) && (
                <UnorderedList
                  title="Margin"
                  values={`${NumberUtil.formatNumberAsDecimal(
                    oppCandidate.proposedMarginTarget
                  )}%`}
                />
              )}
            </OTCardBodyItemCol>
          </OTCardBodyLeft>
          <OTCardBodyRight>
            <OTCardBodyItemCol>
              <OTCardRateWrapper>
                <CpqMarginIndicator
                  candidateOpportunity={oppCandidate}
                  position={oppCandidate.position}
                />
                <UnorderedList title="Rate" values={getRate()} />
              </OTCardRateWrapper>
            </OTCardBodyItemCol>
            <OTCardBodyItemCol>
              <UnorderedList
                title="Last Updated"
                values={DateUtil.displayDate(candidateStatusUpdatedAt)}
              />
            </OTCardBodyItemCol>
          </OTCardBodyRight>
          {showMore && (
            <OTCardBodyFull>
              <Gutter top="16px">
                <OTCardBodyLabel>Talent Submission Note</OTCardBodyLabel>
                <ReadonlyTextarea value={roleFitDescription} />
              </Gutter>
            </OTCardBodyFull>
          )}
          {candidateStatus === CandidateStatuses.Interested &&
            roleFitDescription && (
              <Button
                pattern="secondary"
                variation="minimal"
                fluid
                className="gtm-show-more-less"
                onClick={handleClickShowMore}
                name="SHOWMORE"
                icon={showMore ? 'arrow-up' : 'arrow-right'}
              >
                {showMore ? 'Show Less' : 'Show More'}
              </Button>
            )}
        </OTCardBody>
        <StoreStateTreatment
          name="WARM_MODAL_TREATMENT"
          isModalState
          dataId={candidate.id}
          dataIdName="candidateId"
          storeState={autoWarmingModalState}
          renderLoadedView={data => <AutoWarmingModal form={data} />}
        />
        <FormStateTreatment
          name="COOL_MODAL_TREATMENT"
          isModalState
          dataId={candidate.id}
          dataIdName="candidateId"
          formStore={FormAutoCoolingModalStore}
          renderLoadedView={({ fireSubmit, fireCancel, formData }) => {
            return (
              <AutoCoolingModal
                form={formData}
                onCancel={fireCancel}
                onSend={fireSubmit}
              />
            );
          }}
        />
      </OTCardWrapper>
    </>
  );
}

OpportunityTalentCard.defaultProps = {
  renderContextMenu: null,
};
OpportunityTalentCard.propTypes = {
  opportunityCandidates: arrayOf(talentShape).isRequired,
  opportunity: opportunityShape.isRequired,
  candidate: talentShape.isRequired,
  renderContextMenu: func,
  autoWarmingModalState: EditAutoWarmingModalStore.getDataShape().isRequired,
  roleFitDescriptions: shape({}).isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};

export default connect(state => ({
  autoWarmingModalState: EditAutoWarmingModalStore.select(state),
}))(OpportunityTalentCard);
