import React from 'react';
import moment from 'moment-timezone';
import {
  CalendarEventType,
  Candidate,
  Opportunity,
  User,
  CalendarEventSchema,
  CalendarComponentEvent,
} from '@axiom/validation';
import {
  Grid,
  GridColumn,
  GridRow,
  ImageCircle,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  SmallHeader,
  Calendar,
  CalendarPermissionsUtil,
  Button,
  LayoutItem,
  Gutter,
  Form,
  CalendarTimeZoneUtil,
  useApi,
  FormLabel,
  CondensedMedium,
  useBreakpoint,
  CandidateProfileUtil,
} from '@axiom/ui';
import { PossibleImageSizes, PermissionImpersonationRoles } from '@axiom/const';
import { CalendarEventsUtil } from '@axiom/ui/src/components/form/Calendar/calendar-events-util';
import { z } from 'zod';

import { CalendarApi } from '../../api/calendar-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { useRefreshCandidate } from '../../hooks/useRefreshCandidate';

const dayWeekMode = {
  slots: 2,
  increments: 30,
};

const NAME = 'CALENDAR_COMPONENT' as const;
const CalendarSchema = z.object({
  [NAME]: CalendarEventSchema.array(),
});

export type TalentInterviewAvailabilityModalEditType = {
  candidate: Candidate;
  opportunityId?: Opportunity['id'];
  user: User;
  onClose: () => void;
};

export const TalentInterviewAvailabilityModalEdit = ({
  candidate,
  opportunityId,
  user,
  onClose,
}: TalentInterviewAvailabilityModalEditType) => {
  const guessIana = moment.tz.guess();
  const { isMobile } = useBreakpoint();
  const { refreshCandidate } = useRefreshCandidate(candidate.id);
  const { id: calendarId = null, timezone: talentIana } =
    candidate.calendar || {};
  const timezone = CalendarTimeZoneUtil.getTimeZone(talentIana || guessIana);
  const [{ data: calendarData } = { data: {} }] = useApi(
    calendarId && CalendarApi.readCalendar(calendarId)
  );

  const { userPermissions } = CalendarPermissionsUtil(
    user,
    PermissionImpersonationRoles.envoy
  );

  const calendarPermissions = userPermissions();

  const refreshData = async () => {
    await Promise.all([
      CalendarApi.refreshCalendar(calendarId),
      CalendarApi.refreshCalendarEvents(calendarId),
      opportunityId ? OpportunityStore.load(opportunityId) : null,
      refreshCandidate(candidate.id),
    ]);
  };

  const handleUpdateOnClick = async (
    { [NAME]: formEvents }: { [NAME]: CalendarComponentEvent[] },
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    const hasError = CalendarEventsUtil.getError(formEvents);
    if (hasError) {
      setFieldError(NAME, hasError);

      return;
    }

    const cleanEvents = CalendarEventsUtil.clearAndOrganiseEvents(
      calendarData.events,
      formEvents
    );

    const currentCalendarId =
      calendarId ||
      (
        await CalendarApi.createCalendar({
          candidateId: candidate.id,
          timezone: timezone.iana,
        })
      ).data.id;
    const promises = [];

    // These API calls are in specific order to prevent race-conditions ==================== START
    if (cleanEvents.deleteIds.length) {
      await CalendarApi.deleteCalendarEvents(
        currentCalendarId,
        cleanEvents.deleteIds
      );
    }

    if (cleanEvents.modifiedEvents.length) {
      const payload = cleanEvents.modifiedEvents.reduce((crnt, event) => {
        const { id, ...evnt } = event;
        crnt[id] = evnt;
        return crnt;
      }, {} as { [key: string]: CalendarEventType });

      promises.push(
        CalendarApi.updateCalendarEvents(currentCalendarId, payload)
      );
    }

    if (cleanEvents.newEvents.length) {
      promises.push(
        CalendarApi.createCalendarEvents(
          currentCalendarId,
          cleanEvents.newEvents
        )
      );
    }

    await Promise.all(promises);
    // These API calls are in specific order to prevent race-conditions ==================== END

    refreshData();
    onClose();
  };

  return (
    <Form
      name="INTERVIEW_AUTO_FORM"
      schema={CalendarSchema}
      initialValues={{
        [NAME]: calendarData?.events || [],
      }}
      onSubmit={handleUpdateOnClick}
    >
      {({ fireSubmit }) => {
        return (
          <Modal size="large" name="INTERVIEW_AUTO_MODAL">
            <ModalHeader name="MODAL_HEADER" onClose={onClose}>
              <Layout position="middle" wrap>
                <LayoutItem rightGutter="16px">
                  <ImageCircle
                    imageName={candidate.calculatedDisplayName}
                    src={CandidateProfileUtil.getProfileImageUri(
                      candidate,
                      PossibleImageSizes.W_100
                    )}
                    size="small"
                    name="TALENT_IMAGE"
                  />
                </LayoutItem>
                <LayoutItem fluid>
                  <SmallHeader name="HEADER_TEXT">
                    Edit {candidate.calculatedFirstName}'s interview
                    availability
                  </SmallHeader>
                </LayoutItem>
              </Layout>
            </ModalHeader>
            <ModalSection>
              <Grid>
                <GridRow>
                  <GridColumn widescreenWidth={4} smallScreenWidth={12}>
                    <FormLabel name="pendo-timezone">Time zone</FormLabel>
                    {talentIana ? (
                      <>
                        <CondensedMedium name="TALENT_TIME_ZONE_TEXT">
                          Times are shown in the talent's local time zone.
                        </CondensedMedium>
                        <CondensedMedium name="TALENT_TIME_ZONE">
                          {CalendarTimeZoneUtil.getDisplayZone(timezone)}
                        </CondensedMedium>
                      </>
                    ) : (
                      <CondensedMedium name="NO_TALENT_TIME_ZONE">
                        A default time zone has not been set. Please reach out
                        to the talent to confirm and adjust their preferred time
                        zone.
                      </CondensedMedium>
                    )}
                    <Gutter bottom="16px" />
                  </GridColumn>
                  <GridColumn widescreenWidth={8} smallScreenWidth={12}>
                    <Calendar
                      name={NAME}
                      permissions={calendarPermissions}
                      dayMode={dayWeekMode}
                      weekMode={dayWeekMode}
                      timezone={timezone.iana}
                    />
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button
                onClick={onClose}
                pattern="primary"
                variation="outline"
                name="CANCEL_BUTTON"
              >
                Cancel
              </Button>
              {!isMobile && (
                <Button onClick={fireSubmit} name="UPDATE_BUTTON">
                  Update
                </Button>
              )}
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
