import styled from 'styled-components';
import { AxiomTheme } from '@axiom/ui/src/theme/axiom-theme';

import { getThemeProp } from '../../themes';
import { SIDEBAR_PADDING } from '../../themes/constants';
import { getCandidateStylesByStatus } from '../../utils/color';

export const ButtonFlagIconWrapper = styled.button`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background: none;
  border: none;
  outline: none;
  padding: 0;
  height: 1.25rem;
  margin-right: 0.5rem;
  vertical-align: middle;

  svg path {
    stroke: #fff;
  }
`;

// from ProfileHeader -->

export const HeaderWrapper = styled.header`
  align-items: center;
  background-color: ${props =>
    getCandidateStylesByStatus(props.profileStatus).background};
  border: 2px solid
    ${props => getCandidateStylesByStatus(props.profileStatus).border};
  color: ${props => getCandidateStylesByStatus(props.profileStatus).color};
  flex: none;
  padding: 10px ${SIDEBAR_PADDING}px;
  width: 100%;
`;

export const HeaderTopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -10px;
  position: relative;

  button {
    margin-left: 15px;
    padding: 10px;
  }
`;

export const DivHeaderTopLeftWrapper = styled.div`
  color: ${getThemeProp('darkBlue')};
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  flex: 1 1 auto;
`;

export const TPHStatusWrapper = styled.div`
  text-align: right;
`;

export const TPHEditContainer = styled.section`
  display: flex;
  margin: 5px;
  border: 2px solid #000;
  padding: 5px;
`;

export const TPHEditDropdownWrapper = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

export const TPHSubmissionMessage = styled.div`
  font-weight: bold;
  ${({ isProfileShared }) =>
    !isProfileShared && `color: ${AxiomTheme.colors.crimson};`}
`;
