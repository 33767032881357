import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { CandidatesConst } from '@axiom/const';
import {
  Badge,
  CondensedHeader,
  DataGrid,
  DataGridConnector,
  DataGridHeader,
  DateUtil,
  Dropdown,
  Form,
  Gutter,
  Layout,
  LayoutItem,
} from '@axiom/ui';
import { Candidate, User } from '@axiom/validation';

import {
  readUserTalentResponseType,
  UserTalentApi,
} from '../../api/user-talent-api';
import { AppEventItemModalStore } from '../../stores/app-event-item-modal-store';
import { EventSubjectConst } from '../../stores/events-store';
import { TimeCopyUtil } from '../../utils/time-copy-util';

const { ProfileStatuses, DaysUntilAvailabilityPreferencesTriggerAutoIdle } =
  CandidatesConst;

const allowedProfileStatuses = Object.values(ProfileStatuses).filter(
  value =>
    ![
      ProfileStatuses.Alum,
      ProfileStatuses.AlumDNR,
      ProfileStatuses.Rejected,
    ].includes(value)
) as NonEmptyArray<string>;

const profileStatusOptions = allowedProfileStatuses.map(value => ({
  label: value,
  value,
}));

const getDaysUntilIdleCopy = (
  daysSinceAvailabilityUpdate: number,
  profileStatus: string
) => {
  if (
    profileStatus === ProfileStatuses.Beach ||
    profileStatus === ProfileStatuses.Waitlist
  ) {
    const daysUntilIdle =
      DaysUntilAvailabilityPreferencesTriggerAutoIdle -
      daysSinceAvailabilityUpdate;
    if (daysUntilIdle < 15) {
      return (
        <Badge
          name="daysUntilIdleBadge"
          background={daysUntilIdle <= 7 ? 'red' : 'yellow'}
        >
          {TimeCopyUtil.getDaysCopy(daysUntilIdle)}
        </Badge>
      );
    }
    return `${daysUntilIdle} Days`;
  }

  if (profileStatus === ProfileStatuses.Idle) {
    return 'Idle';
  }

  return '-';
};

const getDaysSinceLastCheckIn = (
  daysSinceLastCheckIn: number,
  profileStatus: string
) => {
  if (
    [
      ProfileStatuses.Active,
      ProfileStatuses.Beach,
      ProfileStatuses.Waitlist,
      ProfileStatuses.PendingActive,
      ProfileStatuses.PendingBeach,
    ].includes(profileStatus)
  ) {
    if (daysSinceLastCheckIn > 30) {
      return (
        <Badge
          name="daysSinceLastCheckInBadge"
          background={daysSinceLastCheckIn > 45 ? 'red' : 'yellow'}
        >
          {TimeCopyUtil.getDaysCopy(daysSinceLastCheckIn)}
        </Badge>
      );
    }
  }

  return daysSinceLastCheckIn >= 0
    ? TimeCopyUtil.getDaysCopy(daysSinceLastCheckIn)
    : '-';
};

export const WorkspaceViewBenchReminders = ({ user }: { user: User }) => {
  const [resultCount, setResultCount] = useState<number>(0);

  const [profileStatusFilter, setProfileStatusFilter] = useState([
    ProfileStatuses.Active,
    ProfileStatuses.Beach,
    ProfileStatuses.Idle,
    ProfileStatuses.PendingActive,
    ProfileStatuses.PendingBeach,
    ProfileStatuses.Waitlist,
  ]);

  const GridConnector = useMemo(
    () =>
      new DataGridConnector({
        getData: args => {
          return UserTalentApi.readUserTalent(
            user.id,
            args.page,
            {
              profileStatus: profileStatusFilter ?? [],
              type: 'owner',
              sort: Object.keys(args.sort).reduce((acc, key) => {
                if (args.sort[key] && !acc) {
                  if (key === 'calculatedDisplayName') {
                    // sort by last name
                    acc = 'lastName';
                  } else {
                    acc = key;
                  }
                }

                return acc;
              }, undefined),
            },
            Object.values(args.sort)[0]
          );
        },
        parseData: (results: readUserTalentResponseType) => {
          setResultCount(results?._meta.resultCount);
          return {
            currentPage: results._meta.currentPage,
            totalPages: results._meta.pageCount,
            data: results.data,
          };
        },
      }),
    [user, profileStatusFilter]
  );

  return (
    <Layout direction="vertical" stretched>
      <LayoutItem topGutter="16px" bottomGutter="16px">
        <Form
          name="WORKSPACE_BENCH_REMINDERS_FILTERS_FORM"
          schema={z.object({
            profileStatus: z.array(z.enum(allowedProfileStatuses)).nullable(),
          })}
          initialValues={{
            profileStatus: profileStatusFilter,
          }}
          submitOnChange
          onSubmit={changedData => {
            setProfileStatusFilter(changedData.profileStatus);
          }}
        >
          {() => (
            <Dropdown
              label="Filter by profile status"
              name="profileStatus"
              options={profileStatusOptions}
              placeholder="Select a profile status"
              displayKey="label"
              valueKey="value"
            />
          )}
        </Form>
      </LayoutItem>
      <LayoutItem>
        <Gutter vertical="8px">
          <CondensedHeader name="TALENT_COUNT">
            {resultCount} Talent
          </CondensedHeader>
        </Gutter>
      </LayoutItem>
      <LayoutItem stretched>
        <DataGrid
          name="WORKSPACE_BENCH_REMINDERS_DATA_GRID"
          displayMode="infinite"
          dataSource={GridConnector}
        >
          <DataGridHeader
            name="calculatedDisplayName"
            displayName="Talent"
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }) => {
              const candidateData = data as Candidate;
              return (
                <Link
                  data-test={`talent-detail-link-${candidateData.id}`}
                  to={`/talent-detail/${candidateData.id}`}
                >
                  {candidateData.calculatedDisplayName}
                </Link>
              );
            }}
          />
          <DataGridHeader
            name="profileStatus"
            displayName="Profile status"
            sortingOrder={['asc', 'desc', null]}
          />
          <DataGridHeader
            name="lastCheckIn"
            displayName="Last check in"
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }) => {
              const { id, lastCheckIn } = data as Candidate;
              return lastCheckIn ? (
                <a
                  data-test={`last-check-in-${id}`}
                  onClick={() => {
                    AppEventItemModalStore.openModal(
                      EventSubjectConst.Candidate,
                      lastCheckIn,
                      user.id
                    );
                  }}
                >
                  {DateUtil.displayDayAndDate(lastCheckIn.scheduledDate)}
                </a>
              ) : (
                '-'
              );
            }}
          />
          <DataGridHeader
            name="daysSinceLastCheckIn"
            displayName="Since check in"
            sortingOrder={['asc', 'desc', null]}
            cellRender={({
              data: { daysSinceLastCheckIn, profileStatus },
            }: {
              data: Candidate;
            }) => {
              return getDaysSinceLastCheckIn(
                daysSinceLastCheckIn,
                profileStatus
              );
            }}
          />
          <DataGridHeader
            name="availabilityPreferencesUpdatedAt"
            displayName="Availability confirmed"
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }: { data: Candidate }) => {
              return data.availabilityPreferencesUpdatedAt
                ? DateUtil.displayDayAndDate(
                    data.availabilityPreferencesUpdatedAt
                  )
                : '-';
            }}
          />
          <DataGridHeader
            name="daysUntilIdle"
            displayName="Until idle"
            cellRender={({
              data: { daysSinceAvailabilityUpdate, profileStatus },
            }: {
              data: Candidate;
            }) => {
              return getDaysUntilIdleCopy(
                daysSinceAvailabilityUpdate,
                profileStatus
              );
            }}
          />
          <DataGridHeader
            name="phoneNumber"
            displayName="Phone number"
            stretched
            cellRender={({ data }: { data: Candidate }) => {
              const phoneNumber = [data.mobilePhone, data.homePhone]
                .filter(Boolean)
                .join(' | ');
              return phoneNumber || '-';
            }}
          />
          <DataGridHeader
            name="personalEmail"
            displayName="Personal email"
            stretched
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data: { personalEmail } }: { data: Candidate }) => {
              return personalEmail || '-';
            }}
          />
          <DataGridHeader
            name="workEmail"
            displayName="Axiom email"
            stretched
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data: { workEmail } }: { data: Candidate }) => {
              return workEmail || '-';
            }}
          />
        </DataGrid>
      </LayoutItem>
    </Layout>
  );
};
