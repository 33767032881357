import React, { useEffect, useRef } from 'react';
import { Candidate, GroupedExperiences, PracticeArea } from '@axiom/validation';

import { StringUtil } from '../../../utils/string-util';
import { PDFExport } from '../../layout/PDFExport/PDFExport';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Layout } from '../../layout/Layout/Layout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';

import { BioPdfFooter } from './BioPdfFooter';
import { BioPdfCandidate } from './BioPdfCandidate';
import { PDFWrapper, PDFFooter } from './BiosPdfStyles';

export type BiosTypes = {
  candidate: Candidate;
  groupedExperiences: GroupedExperiences[];
  practiceAreas: PracticeArea[];
  isAnonymous?: boolean;
  clientUrl: string;
};

export const BioPdf = ({
  candidate,
  groupedExperiences,
  practiceAreas,
  isAnonymous = false,
  clientUrl,
}: BiosTypes) => {
  const pdfRoot = useRef(null);

  useEffect(() => {
    if (pdfRoot?.current?.save) {
      window.addEventListener('focus', () => window.close());

      setTimeout(() => pdfRoot.current.save(), 500);
    }

    return () => {
      window.removeEventListener('focus', () => window.close());
    };
  }, [pdfRoot]);

  const displayName = () => {
    if (isAnonymous && !!candidate.calculatedFirstName) {
      return candidate.calculatedFirstName;
    } else if (!isAnonymous && !!candidate.calculatedDisplayName) {
      return candidate.calculatedDisplayName;
    }
    return 'Axiom Talent';
  };

  const PDFExportPageTemplate = (props: { pageNum: number }) => {
    return (
      <Layout position="center">
        <PDFFooter>
          <Layout stretched position="space-between">
            <ParagraphHeader name="BIO_FOOTER_TALENT">
              {displayName()}
            </ParagraphHeader>
            <ParagraphHeader>Page {props.pageNum}</ParagraphHeader>
          </Layout>
        </PDFFooter>
      </Layout>
    );
  };

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        pageTemplate={props => PDFExportPageTemplate(props)}
        fileName={`${StringUtil.cleanStringForFileName(
          candidate.calculatedFirstName
        )}${
          isAnonymous
            ? ''
            : ` ${StringUtil.cleanStringForFileName(
                candidate.calculatedLastName
              )}`
        } - Axiom`}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
      >
        <Layout name="BIO_PDF" background="panelLight">
          <LayoutItem fluid>
            <Gutter horizontal="40px" vertical="24px">
              <BioPdfCandidate
                candidate={candidate}
                groupedExperiences={groupedExperiences}
                practiceAreas={practiceAreas}
                isAnonymous={isAnonymous}
                clientUrl={clientUrl}
                removeTalentImage
              />
              <BioPdfFooter candidate={candidate} clientUrl={clientUrl} />
            </Gutter>
          </LayoutItem>
        </Layout>
      </PDFExport>
    </PDFWrapper>
  );
};
