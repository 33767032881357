import { ClientRequestConst, EventsConst } from '@axiom/const';
import { DateUtils } from '@axiom/utils';

const { AfcRejectionLossCode, RequestTypes } = ClientRequestConst;

export const getClientRequestPassReasons = rejectionLossCodes => {
  if (!rejectionLossCodes || rejectionLossCodes.length === 0) {
    return '';
  }

  let passReasons = AfcRejectionLossCode[rejectionLossCodes[0]]?.v;

  for (let i = 1; i < rejectionLossCodes.length; i++) {
    passReasons += `\n${AfcRejectionLossCode[rejectionLossCodes[i]]?.v}`;
  }

  return passReasons;
};

export const formatEventPayload = (
  candidate,
  ctaUser,
  request,
  candidateUser
) => {
  const { requestType } = request;
  const scheduledDate = DateUtils.getTodayDateString();
  let description;
  let purpose;
  if (requestType === RequestTypes.InterviewRequested) {
    purpose = 'AFC Interview Requested';
    description = `${ctaUser.fullName} has indicated that they want to interview ${candidate.calculatedDisplayName} on ${scheduledDate}.`;
  } else if (requestType === RequestTypes.HireRequested) {
    purpose = 'AFC Selected';
    description = `${ctaUser.fullName} has indicated that they want to hire ${candidate.calculatedDisplayName} on ${scheduledDate}.`;
  } else if (requestType === RequestTypes.ClientPassed) {
    purpose = 'AFC Pass';
    const internalPassReasons = getClientRequestPassReasons(
      request.rejectionLossCodes
    );
    const feedback = request.notes ? request.notes : 'Reason not provided';
    description =
      `${ctaUser.fullName} has decided not to move forward with ${candidate.calculatedDisplayName}. ` +
      `Here is what influenced their decision.\n\nPass reason:\n\n${internalPassReasons}\n\nClient feedback:\n\n${feedback}`;
  }

  let eventPayload;
  if (description) {
    eventPayload = {
      candidateId: request.candidateId,
      description,
      lastAuthoredBy: candidateUser?.id,
      lastUpdatedBy: request.clientUserId,
      opportunityId: request.opportunityId,
      purpose,
      scheduledDate,
      type: EventsConst.Types.Email,
    };
  }
  return eventPayload;
};
