import React, { useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Dropdown as SDropdown } from 'semantic-ui-react';
import { IconButton } from '@axiom/ui';
import uniq from 'lodash/uniq';

import { Icon } from '../../element/Icon/Icon';

const DropdownWrapper = styled.div``;

export type RawDropdownValueType =
  | string
  | number
  | boolean
  | null
  | Array<string | number | boolean>;

export type RawDropdownDirectionType = 'up' | 'down' | 'default';

export const DirectionMap = {
  up: true,
  down: false,
};

export type RawDropdownProps = {
  allowAdditions?: boolean;
  direction?: RawDropdownDirectionType;
  displayKey: string;
  disabled?: boolean;
  hideIcon?: boolean;
  id?: string;
  invalid?: boolean;
  multiple?: boolean;
  name: string;
  onAddItem?: (value: RawDropdownValueType) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (
    value: RawDropdownValueType,
    e?: React.SyntheticEvent<HTMLElement, Event>,
    valueOption?: Record<string, unknown> | Record<string, unknown>[]
  ) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onType?: (value: RawDropdownValueType) => void;
  options: Array<Record<string, unknown>>;
  placeholder?: string;
  value?: RawDropdownValueType;
  valueKey: string;
};

const divider = ':||:';

const convertValue = (value: RawDropdownValueType | unknown) => {
  if (Array.isArray(value)) {
    return value.join(divider);
  }

  return value;
};
export const RawDropdown = ({
  allowAdditions,
  displayKey,
  direction = 'default',
  disabled = false,
  hideIcon,
  id,
  invalid = false,
  multiple = false,
  name,
  onAddItem,
  onBlur,
  onChange,
  onFocus,
  onType,
  options,
  placeholder,
  value,
  valueKey,
}: RawDropdownProps) => {
  const dropdownOptions = useMemo(() => {
    return options.reduce((acc, o) => {
      const display = get(o, displayKey);
      const v = get(o, valueKey);
      if (display) {
        if (Array.isArray(v) && !multiple) {
          throw new Error(
            `Option '${display}' has an array of values but the schema prop for '${name}' does not accept an array of values`
          );
        }
        acc.push({
          key: convertValue(v),
          text: display,
          value: convertValue(v),
        });
      }

      return acc;
    }, []);
  }, [options]);

  if (multiple && (value === undefined || value === null)) {
    value = [];
  }

  const hasValue = multiple
    ? (value as Array<unknown>)?.length > 0
    : !!value || value === 0;

  return (
    <DropdownWrapper data-test={name}>
      <SDropdown
        allowAdditions={allowAdditions}
        clearable
        upward={DirectionMap[direction as keyof typeof DirectionMap]}
        disabled={disabled}
        error={invalid}
        fluid
        icon={
          !hasValue &&
          !hideIcon && (
            <IconButton
              pattern="secondary"
              variation="minimal"
              icon="arrow-down"
              name="default-dropdown-arrow"
              className="control-icon fixed-dimension-inline"
            />
          )
        }
        id={id}
        multiple={multiple}
        onAddItem={(_e, data) => {
          onAddItem(data.value);
        }}
        onBlur={onBlur}
        onChange={(e, data) => {
          const formattedValue = Array.isArray(data.value)
            ? uniq(
                data.value
                  .reduce((acc, v) => {
                    if (typeof v === 'string') {
                      acc.push(v.includes(divider) ? v.split(divider) : v);
                    } else {
                      acc.push(v);
                    }

                    return acc;
                  }, [])
                  .flat()
              )
            : data.value;

          const valueOption = Array.isArray(formattedValue)
            ? options.filter(opt => formattedValue.includes(get(opt, valueKey)))
            : options.find(opt => get(opt, valueKey) === formattedValue);

          onChange(formattedValue, e, valueOption);
        }}
        onFocus={onFocus}
        onSearchChange={(_e, data) => {
          if (onType) {
            onType(data.searchQuery);
          }
        }}
        options={dropdownOptions}
        placeholder={placeholder}
        renderLabel={({ text }) => {
          // TODO: remove this in Semantic v3
          // This maintains compatibility with Shorthand API in v1 as this might be called in "Label.create()"
          if (typeof text === 'function') {
            return text;
          }

          return {
            content: (
              <>
                {text}
                <span
                  className="pill-control-icon"
                  onClick={e => {
                    e.stopPropagation();
                    const foundPillItem = dropdownOptions.find(
                      op => op.text === text
                    );
                    onChange(
                      (value as Array<string>).filter(
                        v => v !== foundPillItem?.value
                      )
                    );
                  }}
                >
                  <Icon name="x-mark" />
                </span>
              </>
            ),
          };
        }}
        search
        selection
        selectOnBlur={false}
        scrolling
        value={value}
      />
    </DropdownWrapper>
  );
};
