import React from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  EmptyLayout,
  Gutter,
  Paragraph,
  ParagraphHeader,
  SmallHeader,
} from '@axiom/ui/src';

import NoResults from '../../public/svg/no-results-binoculars.svg';

export const OpportunityTalentEmptyProfile = () => {
  return (
    <Card name="opportunity-talent-empty-profile">
      <CardHeader background="blue">
        <SmallHeader>Talent Profile</SmallHeader>
      </CardHeader>
      <CardSection>
        <EmptyLayout
          graphic={<NoResults width="117px" height="117px" />}
          name="TALENT_NOT_SELECTED"
        >
          <Gutter vertical="16px">
            <ParagraphHeader>Talent not selected</ParagraphHeader>
          </Gutter>
          <Paragraph>
            Check out a talent's profile by selecting them from the left-hand
            view. If no talent has been added, try adding them manually or
            getting AI matches.
          </Paragraph>
        </EmptyLayout>
      </CardSection>
    </Card>
  );
};
