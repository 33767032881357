import React from 'react';
import {
  Badge,
  EmptyLayout,
  Flashy,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  Well,
} from '@axiom/ui';
import { OpportunityCandidate } from '@axiom/validation';

import EmptyProjectGraphic from '../../public/svg/empty-project.svg';
import {
  TalentOpportunityCard,
  TalentOpportunityCardType,
  talentOpportunityCardTypeNameFormatter,
} from '../TalentOpportunityCard/TalentOpportunityCard';

export const TalentOpportunityContainer = ({
  type,
  candidateOpportunities,
}: {
  type: TalentOpportunityCardType;
  candidateOpportunities: OpportunityCandidate[];
}) => {
  const typeName = talentOpportunityCardTypeNameFormatter(type);
  return (
    <>
      <Well name={`talent-opportunity-container-${typeName}`}>
        <Layout direction="vertical" verticalGutter="16px">
          <Layout name={`${typeName}-category-layout`}>
            <LayoutItem position="left">
              <Badge background="gray" name="talentOpportunityCountBadge">
                {candidateOpportunities.length}
              </Badge>
            </LayoutItem>
            <Gutter horizontal="4px" />
            <LayoutItem position="right">
              <Flashy bold name="container-type">
                {type}
              </Flashy>
            </LayoutItem>
          </Layout>

          {candidateOpportunities.map(candidateOpp => (
            <div key={candidateOpp.id}>
              <TalentOpportunityCard
                type={type}
                opportunityCandidate={candidateOpp}
              />
            </div>
          ))}
          {candidateOpportunities.length === 0 && (
            <>
              <EmptyLayout
                name={`talent-opportunity-container-${typeName}-empty-layout`}
                graphic={<EmptyProjectGraphic width="117px" height="117px" />}
              >
                <Layout direction="vertical" verticalGutter="16px">
                  <ParagraphHeader
                    name={`talent-opportunity-container-${typeName}-empty-layout-header`}
                  >
                    No Opportunities
                  </ParagraphHeader>
                  <Paragraph
                    name={`talent-opportunity-container-${typeName}-empty-layout-body`}
                  >
                    No opportunities of this type exist.
                  </Paragraph>
                </Layout>
              </EmptyLayout>
            </>
          )}
        </Layout>
      </Well>
      <Gutter vertical="8px" />
    </>
  );
};
