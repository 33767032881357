import React from 'react';
import {
  GroupedExperiences,
  PracticeArea,
  Submission,
  SubmissionCandidate,
} from '@axiom/validation';
import { Button, Layout, LayoutItem } from '@axiom/ui';

import { colors } from '../../../theme/axiom-theme';
import { CandidateHighlightsUtil } from '../../../utils/candidate-highlights-util';
import { CandidateHighlightExperienceSummary } from '../CandidateHighlightExperienceSummary/CandidateHighlightExperienceSummary';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Card } from '../../layout/Card/Card';
import { CardHeader } from '../../layout/Card/CardHeader';
import { CardSection } from '../../layout/Card/CardSection';
import { ClientEngagementsCandidateHeader } from '../ClientEngagementsCandidateHeader/ClientEngagementsCandidateHeader';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { TalentExperiences } from '../TalentExperiences/TalentExperiences';
import { TalentEducation } from '../TalentEducation/TalentEducation';
import { TalentCertifications } from '../TalentCertifications/TalentCertifications';
import { TalentQualifications } from '../TalentQualifications/TalentQualifications';
import { TalentLanguages } from '../TalentLanguages/TalentLanguages';
import TransparentVectorBkg from '../../../assets/graphics/TransparentVectorTop.svg';

export const BioViewCandidate = ({
  candidate,
  groupedExperiences,
  submission = null,
  practiceAreas,
  isAnonymous = false,
  onNavigate = () => {},
}: {
  practiceAreas: PracticeArea[];
  candidate: SubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  submission?: Submission;
  isAnonymous?: boolean;
  onNavigate?: () => void;
}) => {
  return (
    <Gutter bottom="8px">
      <Card name="TALENT_CONTAINER">
        <CardSection
          background={{
            image: TransparentVectorBkg,
            color: colors?.controlInverse,
          }}
        >
          <Layout position="middle">
            <LayoutItem position="left" fluid>
              <ClientEngagementsCandidateHeader
                candidate={candidate}
                isAnonymous={isAnonymous}
                removeTalentImage
              />
            </LayoutItem>
            <LayoutItem position="right">
              <Button name="DOWNLOAD_CTA" onClick={onNavigate}>
                Download
              </Button>
            </LayoutItem>
          </Layout>
        </CardSection>
      </Card>
      <Gutter bottom="16px" />
      {CandidateHighlightsUtil.calculateNumberOfSummaryHighlights(candidate) >
        0 && (
        <Card name="highlights-card-bio-view">
          <CardHeader>
            <SmallHeader>Highlights</SmallHeader>
          </CardHeader>
          <CardSection>
            <CandidateHighlightExperienceSummary
              candidate={candidate}
              submission={submission}
              condensed
            />
          </CardSection>
        </Card>
      )}
      <Gutter bottom="16px" />
      {groupedExperiences.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Experience</SmallHeader>
            </CardHeader>
            <TalentExperiences
              groupedExperiences={groupedExperiences}
              practiceAreas={practiceAreas}
            />
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
      {candidate.degrees?.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Education</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentEducation candidate={candidate} />
            </CardSection>
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
      {candidate.certifications?.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Accomplishments</SmallHeader>
            </CardHeader>
            <CardSection>
              {candidate.certifications?.length > 0 && (
                <>
                  <Gutter bottom="16px">
                    <TalentCertifications candidate={candidate} />
                  </Gutter>
                  <Gutter bottom="16px">
                    <TalentQualifications candidate={candidate} />
                  </Gutter>
                </>
              )}
            </CardSection>
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
      {candidate.languages?.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Languages</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentLanguages candidate={candidate} />
            </CardSection>
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
    </Gutter>
  );
};
