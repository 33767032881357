import React, { useEffect, useMemo, useState } from 'react';
import {
  CalendarIcon,
  DataGrid,
  DataGridConnector,
  DataGridHeader,
  FlagIcon,
  Gutter,
  CondensedHeader,
  IconButton,
  Layout,
  LayoutItem,
  PracticeAreaUtil,
  useApi,
} from '@axiom/ui';
import { PositionsConst } from '@axiom/const';
import { onCheckedRowsArg } from '@axiom/ui/src/components/layout/DataGrid/data-grid-props';
import { useNavigate } from 'react-router-dom';
import { Candidate } from '@axiom/validation';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { DataFetcherParams } from '@axiom/ui/src/components/layout/DataGrid/data-grid-connector';

import { UsersApi } from '../../api/users-api';
import { PracticeAreaApi } from '../../api/practice-area-api';
import { WindowUtil } from '../../utils/window-util';
import { getAnyStyleByStatus } from '../../utils/color';
import { NumberUtil } from '../../utils/number-util';
import { DateUtil } from '../../utils/date-util';
import { AppFindOppForCandidateModalStore } from '../../stores/app-find-opp-for-candidate-modal-store';
import { downloadTalentList } from '../../api/talent';
import { EnvUtil } from '../../utils/env-util';
import { encodeApiObjects } from '../../api/APIHelper';
import { AxiomForTalentUtil } from '../../utils/axiom-for-talent';
import {
  CandidateApi,
  readCandidatesResponseType,
} from '../../api/candidate/candidate-api';
import { generateAddressDisplayName } from '../../redux/selectors/helpers';
import { CandidateAvailabilityModalStore } from '../../stores/candidate-availability-modal-store';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { PreloadedCandidateListFilters } from '../../stores/preloaded-candidate-list-filters';

const sanitizeFilters = ({
  filters,
  page = 0,
  sort = null,
}: DataFetcherParams & { filters: Record<string, unknown> }) => {
  const sendFilters = { ...filters };
  if (sendFilters.skills) {
    sendFilters['experiences.tags'] = sendFilters.skills;
    delete sendFilters.skills;
  }

  if (Number.isInteger(sendFilters.daysToSoonestEngagementEnd)) {
    sendFilters.soonestEngagementEndDate = {
      end: DateUtil.addDaysToNow(sendFilters.daysToSoonestEngagementEnd),
    };

    delete sendFilters.daysToSoonestEngagementEnd;
  }

  ['yearsOfExperience', 'weeklyAvailability', 'compensation'].forEach(
    propName => {
      const propSlice = sendFilters[propName] as {
        start?: unknown;
        end?: unknown;
      };
      if (
        !Number.isInteger(propSlice?.start) &&
        !Number.isInteger(propSlice?.end)
      ) {
        delete sendFilters[propName];
      }
    }
  );

  Object.keys(sendFilters).forEach(propName => {
    if ((sendFilters[propName] as Array<unknown> | string)?.length === 0) {
      delete sendFilters[propName];
    }
  });

  return {
    search: sendFilters.search || undefined,
    filters: sendFilters,
    page: page || undefined,
    sort: sort
      ? Object.keys(sort).reduce((acc, key) => {
          if (sort[key] && !acc) {
            acc = key;
          }

          return acc;
        }, undefined)
      : undefined,
  };
};
export const CandidatesList = () => {
  const filters = useSelector(state =>
    PreloadedCandidateListFilters.select(state)
  ) as Record<string, unknown>;
  const [resultCount, setResultCount] = useState<number>(0);

  const [{ data: practiceAreas }, { data: user }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    UsersApi.getSessionUser()
  );
  const userList = useSelector(state =>
    PreloadedUsersStore.selectDataForById(state)
  );
  const [checkedRows, setCheckedRows] = useState<onCheckedRowsArg>({
    allRowsChecked: false,
    checkedRows: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    setCheckedRows({
      allRowsChecked: false,
      checkedRows: [],
    });
  }, [filters]);

  const GridConnector = useMemo(
    () =>
      new DataGridConnector({
        getData: args => {
          const sendFilters = sanitizeFilters({
            filters: { ...filters },
            ...args,
          });

          return CandidateApi.readCandidates(sendFilters);
        },
        parseData: (results: readCandidatesResponseType) => {
          setResultCount(results?.meta.resultCount);
          return {
            currentPage: results.meta.currentPage,
            totalPages: results.meta.pageCount,
            data: results.data,
          };
        },
      }),
    [filters]
  );

  return (
    <>
      <Gutter vertical="8px">
        <CondensedHeader name="TALENT_COUNT">
          {resultCount} Talent
        </CondensedHeader>
      </Gutter>
      <DataGrid
        name="candidate-list-grid"
        displayMode="infinite"
        dataSource={GridConnector}
        onSelectionChanged={rows => {
          if (rows.length === 1) {
            navigate(WindowUtil.appendQueryParams(`/talent/${rows[0].id}`));
          }
        }}
        rowsChecked={checkedRows}
        onCheckboxGroupChanged={rows => {
          setCheckedRows(rows);
        }}
        calculateRowBadge={rowData => {
          return getAnyStyleByStatus(rowData.profileStatus).background;
        }}
      >
        <DataGridHeader
          name="flagged-candidates"
          pinned="left"
          width={60}
          displayName={() => (
            <LayoutItem position="center">
              <FlagIcon filled />
            </LayoutItem>
          )}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            if (!candidateData.isFlagged) {
              return null;
            }
            return (
              <LayoutItem position="center">
                <FlagIcon filled={!!candidateData.isFlagged} />
              </LayoutItem>
            );
          }}
        />
        <DataGridHeader
          name="calculatedLastName"
          displayName="Talent"
          pinned="left"
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            return candidateData.calculatedDisplayName;
          }}
        />
        <DataGridHeader
          name="profileStatus"
          displayName="Profile status"
          sortingOrder={['asc', null]}
        />
        <DataGridHeader
          name="compensation"
          width={260}
          displayName="Total annual compensation"
          sortingOrder={['asc', null]}
        />
        <DataGridHeader
          name="weeklyAvailability"
          displayName="Current availability"
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            return NumberUtil.formatAsHours(candidateData.weeklyAvailability);
          }}
        />
        <DataGridHeader
          name="soonestEngagementEndDate"
          displayName="Roll off date"
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            const endDate =
              filters.sort === 'alphabeticallySmallestEngagementEndDate'
                ? candidateData.alphabeticallySmallestEngagementEndDate
                : candidateData.soonestEngagementEndDate;

            return (
              <Layout position="middle" horizontalGutter="4px">
                {candidateData.soonestEngagementEndDateStatus ===
                  PositionsConst.EndDateStatuses.Confirmed && (
                  <Gutter top="8px">
                    <span title={PositionsConst.EndDateStatuses.Confirmed}>
                      <CalendarIcon withCheck />
                    </span>
                  </Gutter>
                )}
                <LayoutItem>{DateUtil.displayDate(endDate)}</LayoutItem>
              </Layout>
            );
          }}
        />
        <DataGridHeader
          name="practiceAreaId"
          displayName="Practice area"
          cellRender={({ data }: { data: Candidate }) => {
            return PracticeAreaUtil.getPracticeAreaAndSpecialty(
              practiceAreas,
              data.practiceAreaId
            )?.practiceArea?.name;
          }}
        />
        <DataGridHeader
          name="addressCity"
          displayName="Residence"
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            return generateAddressDisplayName(candidateData);
          }}
        />
        <DataGridHeader
          name="ownerUser.lastName"
          displayName="Talent owner"
          sortingOrder={['asc', null]}
          cellRender={({ data }: { data: Candidate }) => {
            return userList[data.ownerUserId]?.fullName;
          }}
        />
        <DataGridHeader
          name="pinner-options"
          pinned="right"
          width={200}
          displayName={() =>
            (checkedRows.allRowsChecked ||
              checkedRows.checkedRows.length > 0) && (
              <Layout horizontalGutter="8px" position="center">
                <IconButton
                  pattern="secondary"
                  icon="plus"
                  name="ADDRELATIONSHIPBUTTON"
                  title="Add to Opportunity Log"
                  onClick={() => {
                    if (checkedRows.checkedRows.length) {
                      AppFindOppForCandidateModalStore.openModal(
                        checkedRows.checkedRows.reduce((acc, row) => {
                          acc.push(row.id);
                          return acc;
                        }, [])
                      );
                    }
                  }}
                />

                {!user.roles.includes('EnvoySales') && (
                  <IconButton
                    pattern="secondary"
                    title="Export as CSV"
                    name="EXPORTASCSVBUTTON"
                    data-sourcepage="bench"
                    className="gtm-csv-download"
                    onClick={async () => {
                      const search = checkedRows.allRowsChecked
                        ? sanitizeFilters({
                            filters,
                            sort: null,
                            page: null,
                          })
                        : {
                            filters: {
                              ids: Array.from(
                                checkedRows.checkedRows.reduce((acc, row) => {
                                  acc.push(row.id);
                                  return acc;
                                }, [])
                              ),
                            },
                          };
                      await downloadTalentList({ request: search });

                      const uri = `${
                        EnvUtil.apiEnvoyUrl
                      }/downloads/candidates?${encodeApiObjects({
                        request: search,
                      })}`;
                      window.location.href = uri;
                    }}
                    icon="download"
                  />
                )}
              </Layout>
            )
          }
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            return (
              <Layout position="center middle" horizontalGutter="8px">
                <LayoutItem
                  borderRight={data ? '1px solid contentBreak' : null}
                >
                  <Gutter right="8px">
                    <IconButton
                      title="Show availability calculation"
                      onClick={() =>
                        CandidateAvailabilityModalStore.openModal(
                          candidateData.id
                        )
                      }
                      pattern="secondary"
                      variation="minimal"
                      icon="clock"
                      name="AVAILABILITY_MODAL_CLOCK_ICON"
                    />
                  </Gutter>
                </LayoutItem>
                <LayoutItem
                  borderRight={data ? '1px solid contentBreak' : null}
                >
                  <Gutter right="8px">
                    <IconButton
                      icon="popout"
                      pattern="secondary"
                      variation="minimal"
                      toTab={`/talent-detail/${candidateData.id}`}
                      title={`Open "${candidateData.calculatedDisplayName}" in new tab`}
                    />
                  </Gutter>
                </LayoutItem>
                <LayoutItem
                  borderRight={data ? '1px solid contentBreak' : null}
                >
                  <Gutter right="8px">
                    <IconButton
                      icon="avatar"
                      pattern="secondary"
                      variation="minimal"
                      title={`Open ${
                        AxiomForTalentUtil.isFullBiosByStatus(
                          candidateData.profileStatus
                        ) && candidateData.isProfileShared
                          ? 'Bio'
                          : 'Anonymous Bio'
                      }`}
                      onClick={() => {
                        return AxiomForTalentUtil.isFullBiosByStatus(
                          candidateData.profileStatus
                        )
                          ? AxiomForTalentUtil.openBio(candidateData.id)
                          : AxiomForTalentUtil.openAnonymousBio(
                              candidateData.id
                            );
                      }}
                    />
                  </Gutter>
                </LayoutItem>
                <LayoutItem>
                  <IconButton
                    icon="tableau"
                    pattern="secondary"
                    variation="minimal"
                    toTab={`${EnvUtil.tableauSimilarTalentUrl}?${qs.stringify({
                      TNP: candidateData.calculatedDisplayName,
                    })}`}
                    title="Open Tableau to find similar talent"
                  />
                </LayoutItem>
              </Layout>
            );
          }}
        />
      </DataGrid>
    </>
  );
};
