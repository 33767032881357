import React from 'react';
import { Paragraph, ReadonlyTextarea } from '@axiom/ui';

interface TalentExperiencesItemDescriptionProps {
  descriptionText: string;
}

export const TalentExperiencesItemDescription = ({
  descriptionText,
}: TalentExperiencesItemDescriptionProps) => {
  const trimmedText = descriptionText.trim();
  if (!trimmedText) return null;

  return (
    <Paragraph name="EXPERIENCE_DESCRIPTION_TEXT">
      <ReadonlyTextarea value={trimmedText} />
    </Paragraph>
  );
};
