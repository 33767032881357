import React, { FC } from 'react';
import { Opportunity, OpportunityCandidate } from '@axiom/validation';
import { CondensedMedium, Flashy, Icon, Layout } from '@axiom/ui';
import { CandidateOpportunityUtils } from '@axiom/utils';
import { FlashyProps } from '@axiom/ui/src/components/element/Flashy/Flashy';

import { NumberUtil } from '../../utils/number-util';

interface OpportunityTalentRateMarginProps {
  opportunityCandidate: OpportunityCandidate;
  opportunity: Opportunity;
}

export const OpportunityTalentRateMargin: FC<
  OpportunityTalentRateMarginProps
> = ({ opportunityCandidate, opportunity }) => {
  let rateAndMargin = 'Not Set';
  let rateColor: FlashyProps['color'] = 'iconSecondary';
  const hasSubmittedRate =
    CandidateOpportunityUtils.hasSubmittedRate(opportunityCandidate);

  if (hasSubmittedRate) {
    rateAndMargin = `${NumberUtil.formatCurrency(
      opportunityCandidate.proposedHourlyRate,
      opportunity.currency
    )}`;
    if (opportunityCandidate.proposedMarginTarget) {
      rateAndMargin += ` (${opportunityCandidate.proposedMarginTarget}%)`;
    }

    rateColor = 'dataVizYellow';
    if (CandidateOpportunityUtils.isWithinMargin(opportunityCandidate)) {
      rateColor = 'dataVizGreen';
    }
    if (CandidateOpportunityUtils.isOverMargin(opportunityCandidate)) {
      rateColor = 'dataVizYellow';
    }
  }

  return (
    <Layout
      direction="horizontal"
      position="left middle"
      horizontalGutter="4px"
    >
      <Flashy color={rateColor} name="RATE_INDICATOR">
        <Layout direction="vertical" position="middle center">
          <Icon name="dollar" />
        </Layout>
      </Flashy>
      <CondensedMedium name="RATE_MARGIN">{rateAndMargin}</CondensedMedium>
    </Layout>
  );
};
