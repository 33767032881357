import { shape, string, number, arrayOf } from 'prop-types';
import pick from 'lodash/pick';
import { CandidateOpportunitiesConst } from '@axiom/const';
import moment from 'moment';

import { getOpportunitiesByTalentId } from '../api/talentOpportunities';
import { getTalent } from '../api/talent';
import GenericStore from '../classes/generic-store';

const { CandidateStatuses } = CandidateOpportunitiesConst;

class CandidateAvailabilityModalStoreClass extends GenericStore {
  openModal = candidateId =>
    this.setState(
      Promise.all([
        getTalent(candidateId),
        getOpportunitiesByTalentId(candidateId),
      ]).then(([{ data: candidate }, { data: candidacyOpportunities }]) => ({
        ...pick(candidate, [
          'calculatedDisplayName',
          'weeklyAvailability',
          'currentAvailabilityUpdatedAt',
          'calculatedDesiredWeeklyHours',
          'desiredHoursUpdatedAt',
          'soonestEngagementEndDate',
          'soonestEngagementEndDateStatus',
        ]),
        activeEngagements: candidacyOpportunities
          .filter(opp => opp.candidateStatus === CandidateStatuses.Engaged)
          .sort((a, b) => {
            if (!moment(a.position.endDate).isSame(b.position.endDate)) {
              return moment(a.position.endDate).isBefore(b.position.endDate)
                ? -1
                : 1;
            }
            return a.accountName.localeCompare(b.accountName);
          }),
      }))
    );

  closeModal = () => this.clearState();

  getDataShape = () =>
    shape({
      calculatedDisplayName: string,
      weeklyAvailability: number,
      currentAvailabilityUpdatedAt: string,
      calculatedDesiredWeeklyHours: number,
      desiredHoursUpdatedAt: string,
      soonestEngagementEndDate: string,
      soonestEngagementEndDateStatus: string,
      activeEngagements: arrayOf(shape({})),
    });
}

export const CandidateAvailabilityModalStore =
  new CandidateAvailabilityModalStoreClass();
