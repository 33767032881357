import { CandidateOpportunitiesConst } from '@axiom/const';
import { AxiomTheme } from '@axiom/ui/src/theme/axiom-theme';

import { CLOSED_WON, CLOSED_LOST } from '../api/constants';
import {
  BENCHED,
  CONTRACTING,
  DEVELOPING,
  QUALIFIED,
  READY,
  UNQUALIFIED,
} from '../models/opportunities';

const {
  CandidateStatuses: {
    Completed,
    Cooled,
    Engaged,
    Interviewing,
    Rejected,
    Removed,
    RepBioShare,
    Selected,
    ShortList,
    Submitted,
    Suggested,
    TalentOptOut,
    Warmed,
    WarmedUnsure,
    WarmedYes,
    Interested,
    InterestedRejected,
  },
  OppCandMlRecStatuses: { AiRemoved, AiSuggested },
} = CandidateOpportunitiesConst;

const COOLED_COLORS = {
  color: '#fff',
  background: '#737873',
  border: '#444744',
};
const SUBMITTED_COLORS = {
  color: '#fff',
  background: '#517a9e',
  border: '#364d62',
};
const WARMED_COLORS = {
  color: '#fff',
  background: '#2c7584',
  border: '#184149',
};
const SUGGESTED_COLORS = {
  color: '#fff',
  background: '#607b64',
  border: '#425144',
};

const INTERESTED_COLORS = {
  color: '#fff',
  background: '#9cb320',
  border: '#414b03',
};

const AI_SUGGESTED_COLORS = {
  color: AxiomTheme.colors.textReversed,
  background: AxiomTheme.colors.controlSecondary,
  border: AxiomTheme.colors.controlActiveSecondary,
};

export const EMPLOYMENT_STATUS_STYLES = {
  ACTIVE_OPPORTUNITY: {
    border: '#000',
    color: '#fff',
    background: '#001d4c',
  },
  [AiRemoved]: COOLED_COLORS,
  [AiSuggested]: AI_SUGGESTED_COLORS,
  [BENCHED]: {
    color: '#fff',
    background: '#C7D66D',
    border: '#C7D66D',
  },
  [CLOSED_LOST]: {
    color: '#fff',
    background: '#2B2B2B',
    border: '#2B2B2B',
  },
  [CLOSED_WON]: {
    color: '#fff',
    background: '#2B2B2B',
    border: '#2B2B2B',
  },
  [Completed]: {
    color: '#fff',
    background: '#161726',
    border: '#000',
  },
  [CONTRACTING]: {
    color: '#fff',
    background: '#C7D66D',
    border: '#C7D66D',
  },
  [Cooled]: COOLED_COLORS,
  [DEVELOPING]: {
    color: '#fff',
    background: '#2B2B2B',
    border: '#2B2B2B',
  },
  [Engaged]: {
    color: '#fff',
    background: '#284c80',
    border: '#001d4c',
  },
  INACTIVE_OPPORTUNITY: {
    border: '#545657',
    color: '#fff',
    background: '#737678',
  },
  [Interested]: INTERESTED_COLORS,
  [InterestedRejected]: COOLED_COLORS,
  [Interviewing]: SUBMITTED_COLORS,
  [QUALIFIED]: {
    color: '#fff',
    background: '#4D7C8A',
    border: '#4D7C8A',
  },
  [READY]: {
    color: '#fff',
    background: '#92B5A5',
    border: '#92B5A5',
  },
  [Rejected]: COOLED_COLORS,
  [Removed]: COOLED_COLORS,
  [RepBioShare]: SUGGESTED_COLORS,
  [Selected]: {
    color: '#fff',
    background: '#2265a4',
    border: '#233e3a',
  },
  [ShortList]: SUGGESTED_COLORS,
  [Submitted]: SUBMITTED_COLORS,
  [Suggested]: SUGGESTED_COLORS,
  [TalentOptOut]: COOLED_COLORS,
  [UNQUALIFIED]: {
    color: '#fff',
    background: '#93A3B1',
    border: '#93A3B1',
  },
  [Warmed]: WARMED_COLORS,
  [WarmedUnsure]: WARMED_COLORS,
  [WarmedYes]: SUBMITTED_COLORS,
};
