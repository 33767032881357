import React, { PropsWithChildren } from 'react';
import {
  BarredLocation,
  Candidate,
  CandidateOpportunity,
  Opportunity,
  OpportunityCandidate,
  Taxonomy,
  Position,
} from '@axiom/validation';
import { Flashy } from '@axiom/ui';
import {
  CandidateOpportunitiesConst,
  CountryCodesConst,
  OpportunitiesConst,
  PositionsConst,
  StateCodesConst,
} from '@axiom/const';

import { CandidateUtil } from '../../utils/candidate-util';
import { states } from '../../api/constants';

const { CandidateStatuses } = CandidateOpportunitiesConst;
const { CountryCodesAbbreviations } = CountryCodesConst;
const { WorkLocation } = OpportunitiesConst;
const { AbbreviatedStateCode, StateCodeMap } = StateCodesConst;

export interface MatchChecklistItem extends PropsWithChildren {
  id: string;
  isMatched: boolean;
}

const { TalentTypes } = PositionsConst;

const statesAllowingRemoteWork = [
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'Florida',
  'Georgia',
  'Hawaii',
  'Illinois',
  'Maine',
  'Maryland',
  'Minnesota',
  'NewJersey',
  'NewYork',
  'NorthCarolina',
  'Ohio',
  'SouthCarolina',
  'Tennessee',
  'Utah',
  'Virginia',
  'Washington',
  'Wisconsin',
];

const getStateType = (state: string) => {
  if (states[state as keyof typeof states]) {
    return statesAllowingRemoteWork.includes(state.replaceAll(' ', ''))
      ? ' (green state)'
      : ' (red state)';
  }
  return '';
};

const isBarred = (barredLocations: BarredLocation[], state: string) =>
  barredLocations.some(
    location => location.addressState?.toLowerCase() === state?.toLowerCase()
  );

const isLawyerTalentType = (position: Position = {}) => {
  return position?.talentType === TalentTypes.Lawyer;
};

export const MatchChecklistUtil = {
  getCountryChecklistItem(
    candidate: Candidate,
    opportunity: Opportunity
  ): MatchChecklistItem | undefined {
    if (
      !candidate.addressCountry ||
      !candidate.addressCountryCode ||
      !opportunity.requiredTalentCountryCode
    ) {
      return undefined;
    }

    return {
      id: 'checklist-item-country',
      isMatched:
        candidate.addressCountryCode === opportunity.requiredTalentCountryCode,
      children: (
        <>
          Located in <Flashy bold>{candidate.addressCountry}</Flashy>
        </>
      ),
    };
  },
  getStateChecklistItem(
    candidate: Candidate,
    opportunity: Opportunity
  ): MatchChecklistItem | undefined {
    if (
      opportunity.requiredTalentCountryCode === 'US' &&
      opportunity.addressState &&
      candidate.addressCountryCode === 'US'
    ) {
      const id = 'checklist-item-state';
      const isMatched =
        opportunity.addressState ===
        AbbreviatedStateCode(candidate.addressState);

      if (!candidate.addressState) {
        return {
          id,
          isMatched,
          children: 'Unknown home state',
        };
      }

      return {
        id,
        isMatched,
        children: (
          <>
            Located in <Flashy bold>{candidate.addressState}</Flashy>
          </>
        ),
      };
    }

    return undefined;
  },
  getIndustryChecklistItem(
    candidate: Candidate,
    opportunity: Opportunity,
    industries: Taxonomy['industries']
  ): MatchChecklistItem | undefined {
    if (!opportunity.account?.industryValue) {
      return undefined;
    }

    const candidateIndustry = candidate.industries.find(
      i => i.industryValue === opportunity.account.industryValue
    );

    const yearsOfExperience = candidateIndustry?.yearsOfExperience || '0';
    const yearString = CandidateUtil.formatYOE(yearsOfExperience);
    const isMatched = Number(yearsOfExperience) > 0;

    return {
      id: 'checklist-item-industry',
      isMatched,
      children: (
        <>
          {isMatched ? yearString : 'No'} experience in{' '}
          <Flashy bold>
            {industries[opportunity.account?.industryValue]?.displayValue}
          </Flashy>
        </>
      ),
    };
  },
  getPracticeAreaChecklistItem(
    candidate: Candidate,
    opportunity: Opportunity
  ): MatchChecklistItem | undefined {
    if (!opportunity.practiceAreaId || !candidate.practiceAreaId) {
      return undefined;
    }

    return {
      id: 'checklist-item-practice-area',
      isMatched:
        opportunity.practiceAreaId === candidate.practiceArea.parent?.id ||
        opportunity.practiceAreaId === candidate.practiceAreaId,
      children: (
        <>
          Practice Area is{' '}
          <Flashy bold>
            {candidate.practiceArea.parent?.name ?? candidate.practiceArea.name}
          </Flashy>
        </>
      ),
    };
  },
  getBarredToWorkOnsiteChecklistItem(
    candidate: Candidate,
    opportunity: Opportunity,
    opportunityCandidate: OpportunityCandidate
  ): MatchChecklistItem | undefined {
    if (
      opportunity.country === CountryCodesAbbreviations.US &&
      (opportunityCandidate.position
        ? isLawyerTalentType(opportunityCandidate.position)
        : opportunity.positions?.some(position => isLawyerTalentType(position)))
    ) {
      const id = 'checklist-item-barred-to-work-onsite';
      // onsite, hybrid or no preference
      if (
        opportunity.workLocation !== WorkLocation.Remote &&
        opportunity.addressState
      ) {
        const state =
          StateCodeMap.get(opportunity.addressState) ??
          opportunity.addressState;
        // is candidate admitted to work in the opportunity state
        if (isBarred(candidate.barredLocations, state)) {
          return {
            id,
            isMatched: true,
            children: (
              <>
                Admitted to work in <Flashy bold>{state}</Flashy>
              </>
            ),
          };
        }
        return {
          id,
          isMatched: false,
          children: (
            <>
              Not admitted to work in{' '}
              <Flashy bold>
                {state}
                {`${getStateType(state)}`}
              </Flashy>
            </>
          ),
        };
      }
    }
    return undefined;
  },
  getBarredToWorkRemotelyChecklistItem(
    candidate: Candidate,
    opportunity: Opportunity,
    opportunityCandidate: OpportunityCandidate
  ): MatchChecklistItem | undefined {
    if (
      opportunity.country === CountryCodesAbbreviations.US &&
      candidate.addressCountryCode === CountryCodesAbbreviations.US &&
      (opportunityCandidate.position
        ? isLawyerTalentType(opportunityCandidate.position)
        : opportunity.positions?.some(position => isLawyerTalentType(position)))
    ) {
      const id = 'checklist-item-barred-to-work-remotely';
      // remote, hybrid or no preference
      if (
        opportunity.workLocation !== WorkLocation.Onsite &&
        candidate.addressState
      ) {
        // candidate is admitted to work in the state they live
        if (isBarred(candidate.barredLocations, candidate.addressState)) {
          return {
            id,
            isMatched: true,
            children: (
              <>
                Admitted to work remotely in{' '}
                <Flashy bold>{candidate.addressState}</Flashy>
              </>
            ),
          };
        }
        return {
          id,
          isMatched: false,
          children: (
            <>
              Not admitted to work remotely in{' '}
              <Flashy bold>
                {candidate.addressState}
                {`${getStateType(candidate.addressState)}`}
              </Flashy>
            </>
          ),
        };
      }
    }
    return undefined;
  },
  getCrossFulfillmentChecklistItem(
    opportunity: Opportunity,
    candidateOpportunities: Array<CandidateOpportunity>
  ): MatchChecklistItem | undefined {
    const crossFulfillStatuses = [
      CandidateStatuses.Selected,
      CandidateStatuses.Interviewing,
      CandidateStatuses.Submitted,
      CandidateStatuses.WarmedYes,
    ];
    const otherOpportunities = candidateOpportunities.filter(
      co =>
        co.opportunityId !== opportunity.id &&
        crossFulfillStatuses.includes(co.candidateStatus)
    );

    if (otherOpportunities.length > 0) {
      const countOppsWithStatus = (status: string): number => {
        return otherOpportunities.filter(co => co.candidateStatus === status)
          .length;
      };

      const selectedOpportunities = countOppsWithStatus(
        CandidateStatuses.Selected
      );
      const interviewingOpportunities = countOppsWithStatus(
        CandidateStatuses.Interviewing
      );
      const submittedOpportunities = countOppsWithStatus(
        CandidateStatuses.Submitted
      );
      const optedInOpportunities = countOppsWithStatus(
        CandidateStatuses.WarmedYes
      );

      const crossFulfillMessages: Array<string> = [];

      if (selectedOpportunities > 0) {
        crossFulfillMessages.push(`${selectedOpportunities} Selected`);
      }
      if (interviewingOpportunities > 0) {
        crossFulfillMessages.push(`${interviewingOpportunities} Interviewing`);
      }
      if (submittedOpportunities > 0) {
        crossFulfillMessages.push(`${submittedOpportunities} Submitted`);
      }
      if (optedInOpportunities > 0) {
        crossFulfillMessages.push(`${optedInOpportunities} Opted In`);
      }

      return {
        id: 'checklist-item-cross',
        isMatched: false,
        children: (
          <>
            <Flashy bold>Cross:</Flashy> {crossFulfillMessages.join(', ')}
          </>
        ),
      };
    }

    return undefined;
  },
  getAvailabilityHoursChecklistItem(
    candidate: Candidate,
    opportunity: Opportunity,
    opportunityCandidate: OpportunityCandidate
  ): MatchChecklistItem | undefined {
    if (
      opportunityCandidate.position?.billingHoursPerWeek ||
      opportunity.positions?.length === 1
    ) {
      const { weeklyAvailability = 0 } = candidate;
      const positionHours =
        opportunityCandidate.position?.billingHoursPerWeek ||
        opportunity.positions[0].billingHoursPerWeek;
      const isMatched = weeklyAvailability >= positionHours;

      return {
        id: 'checklist-item-hours-availability',
        isMatched,
        children: (
          <>
            {!isMatched && weeklyAvailability > 0 ? 'Only ' : undefined}
            <Flashy bold>
              {Math.round(weeklyAvailability)}{' '}
              {weeklyAvailability === 1 ? 'hour' : 'hours'}
            </Flashy>{' '}
            available
          </>
        ),
      };
    }

    return undefined;
  },
  getOpenToLowerEscItem(
    opportunityCandidate: OpportunityCandidate
  ): MatchChecklistItem | undefined {
    if (!opportunityCandidate.openToLowerEsc) {
      return undefined;
    }

    return {
      id: 'checklist-item-open-to-lower-esc',
      isMatched: true,
      children: (
        <>
          OK with{' '}
          <Flashy bold>
            lower ESC
            {opportunityCandidate.openToLowerEscCompensationPercentage
              ? ` (${opportunityCandidate.openToLowerEscCompensationPercentage}%)`
              : ''}
          </Flashy>
        </>
      ),
    };
  },
};
