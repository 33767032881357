import {
  CondensedHeader,
  CondensedLarge,
  DateUtil,
  Layout,
  LayoutItem,
} from '@axiom/ui/src';
import { Opportunity } from '@axiom/validation';
import React from 'react';

export const WorkspaceViewTimeToSubmissionTooltipBody = ({
  opportunity,
}: {
  opportunity: Opportunity;
}) => {
  return (
    <>
      <Layout direction="vertical" name="qualifiedAt">
        <LayoutItem>
          <CondensedHeader>Qualified at:</CondensedHeader>
        </LayoutItem>
        <LayoutItem>
          <CondensedLarge>
            {DateUtil.displayTimestamp(opportunity.qualifiedAt)}
          </CondensedLarge>
        </LayoutItem>
      </Layout>
      {opportunity.firstCandidateSubmittedAt && (
        <Layout direction="vertical" name="firstSubmittedAt">
          <LayoutItem>
            <CondensedHeader>First Submission at:</CondensedHeader>
          </LayoutItem>
          <LayoutItem>
            <CondensedLarge>
              {DateUtil.displayTimestamp(opportunity.firstCandidateSubmittedAt)}
            </CondensedLarge>
          </LayoutItem>
        </Layout>
      )}
    </>
  );
};
