import { CalendarComponentEvent } from '@axiom/validation';

import { calendarPermissionsType } from '../../../utils/calendar-permissions-util';

import { EventStates } from './CalendarEventsConst';

export const useAccessors = (permissions: calendarPermissionsType) => {
  const handleDraggableAccessor = (event: CalendarComponentEvent) => {
    return (
      event.state === EventStates.NEW ||
      (event.busy && permissions.staticEvent.canMove) ||
      (!event.busy && permissions.eventBoundary.canMove)
    );
  };

  const handleResizableAccessor = (event: CalendarComponentEvent) => {
    return (event.busy && permissions.staticEvent.canResize) || !event.busy;
  };

  return {
    handleDraggableAccessor,
    handleResizableAccessor,
  };
};
