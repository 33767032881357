const ProfileCompletionCriteria = {
  needsNativeLanguage: 'needsNativeLanguage',
  needsNonAxiomExperience: 'needsNonAxiomExperience',
  needsNoAxiomExperienceWithBlankDescription:
    'needsNoAxiomExperienceWithBlankDescription',
  needsNoExperienceWithMissingIndustry: 'needsNoExperienceWithMissingIndustry',
  needsLegalTechSkills: 'needsLegalTechSkills',
  needsDegrees: 'needsDegrees',
  needsCertifications: 'needsCertifications',
  needsAcceptableSummary: 'needsAcceptableSummary',
  needsValidName: 'needsValidName',
  needsPracticeStartYear: 'needsPracticeStartYear',
};

const ProfileCompletionSummaryBanner = {
  emptyTitle: 'Write a profile summary',
  emptyContent:
    'Your summary is the first thing a client sees. Help them get to know you and your experience better. We recommend writing at least 200 characters. You have a maximum of 500 characters.',
  tooShortTitle: 'Improve your profile summary',
  tooShortContent:
    'Your summary is too short. We find that summaries are received best by clients when they are between 200-500 characters.',
};

const ProfileCompletionNameBanner = {
  allCaps: 'Use sentence case, not all caps, for your display name.',
  qualifications:
    'Remove qualifications (JD, MBA, LLM, etc.) from your display name.',
  titles: 'Remove the title (Esq., Mr., Mrs., etc.) from your display name.',
};

const ProfileCompletionExperienceIndustryBanner = {
  missingTitle: 'Add an Industry',
  missingContent:
    'We require an Industry for each experience. This helps Axiom find your profile and know what types of engagements to submit you for in the future.',
};

const ProfileCompletionLegalTechSkillsBanner = {
  missingTitle: 'Add legal tech skills',
  missingContent:
    'Some roles require proficiency with specific tools. Add your proficiency so we can match and promote you for the opportunities that fit your skillset.',
};

const ProfileCompletionAccomplishmentsBanner = {
  missingTitle: 'Add an accomplishment',
  missingContent:
    'Demonstrate your passion and help your profile stand out by adding certifications, awards, and qualifications.',
};

const ProfileCompletionLanguagesBanner = {
  missingTitle: 'Add a language',
  missingContent:
    'Add your primary language and any other languages you speak. Make sure to include proficiency, skill, and CEFR (as needed).',
};

const ProfileCompletionCriteriaDetails: Record<
  ProfileCompletionCriteria,
  ProfileCompletionCriteriaDetail
> = {
  [ProfileCompletionCriteria.needsPracticeStartYear]: {
    name: ProfileCompletionCriteria.needsPracticeStartYear,
    remediation: 'Add years of experience.',
    additionalDetail: `Tell us how many years you've been practicing law in your`,
  },
  [ProfileCompletionCriteria.needsNativeLanguage]: {
    name: ProfileCompletionCriteria.needsNativeLanguage,
    remediation: 'Add a language.',
    additionalDetail:
      'Make sure to add your speaking, reading and writing proficiency. Don’t forget to add your primary language!',
  },
  [ProfileCompletionCriteria.needsNoAxiomExperienceWithBlankDescription]: {
    name: ProfileCompletionCriteria.needsNoAxiomExperienceWithBlankDescription,
    remediation: 'Describe your Axiom experience.',
    additionalDetail:
      'Please write at least one bullet point, and a minimum of 100 characters.',
  },
  [ProfileCompletionCriteria.needsNonAxiomExperience]: {
    name: ProfileCompletionCriteria.needsNonAxiomExperience,
    remediation: 'Add a non-Axiom experience.',
    additionalDetail:
      'Please write at least one bullet point, and a minimum of 100 characters.',
  },
  [ProfileCompletionCriteria.needsNoExperienceWithMissingIndustry]: {
    name: ProfileCompletionCriteria.needsNoExperienceWithMissingIndustry,
    remediation: 'Add an industry to each experience.',
  },
  [ProfileCompletionCriteria.needsLegalTechSkills]: {
    name: ProfileCompletionCriteria.needsLegalTechSkills,
    remediation: 'Add legal tech skills.',
  },
  [ProfileCompletionCriteria.needsDegrees]: {
    name: ProfileCompletionCriteria.needsDegrees,
    remediation: 'Complete your education section.',
    additionalDetail:
      'Make sure that at least one degree is present, and all fields are filled out.',
  },
  [ProfileCompletionCriteria.needsCertifications]: {
    name: ProfileCompletionCriteria.needsCertifications,
    remediation: 'Add an accomplishment.',
  },
  [ProfileCompletionCriteria.needsAcceptableSummary]: {
    name: ProfileCompletionCriteria.needsAcceptableSummary,
    remediation: 'Add a profile summary.',
  },
  [ProfileCompletionCriteria.needsValidName]: {
    name: ProfileCompletionCriteria.needsValidName,
    remediation: 'Update your display name.',
    additionalDetail:
      'Make sure your display name follows the personalization guidance.',
  },
};

export type ProfileCompletionCriteria =
  typeof ProfileCompletionCriteria[keyof typeof ProfileCompletionCriteria];

export type ProfileCompletionCriteriaDetail = {
  name: string;
  remediation: string;
  additionalDetail?: string;
};

export const ProfileCompletionConst = {
  ProfileCompletionCriteria,
  ProfileCompletionCriteriaDetails,
  ProfileCompletionExperienceIndustryBanner,
  ProfileCompletionLegalTechSkillsBanner,
  ProfileCompletionSummaryBanner,
  ProfileCompletionNameBanner,
  ProfileCompletionAccomplishmentsBanner,
  ProfileCompletionLanguagesBanner,
  minimumAcceptableSummaryLength: 200,
} as const;
