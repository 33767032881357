export const CountryCodes = {
  AO: 'Angola',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BE: 'Belgium',
  BU: 'Bermuda',
  BA: 'Bosnia and Herzegovina',
  BR: 'Brazil',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  KH: 'Cambodia',
  CA: 'Canada',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EG: 'Egypt',
  FI: 'Finland',
  FR: 'France',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GB: 'Great Britain',
  GR: 'Greece',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IN: 'India',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JP: 'Japan',
  JE: 'Jersey',
  KE: 'Kenya',
  KR: 'Korea; Republic Of (South)',
  LV: 'Latvia',
  LB: 'Lebanon',
  LU: 'Luxembourg',
  MO: 'Macau',
  MY: 'Malaysia',
  MT: 'Malta',
  MU: 'Mauritius',
  MX: 'Mexico',
  MJ: 'Montenegro',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NG: 'Nigeria',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russian Federation',
  BL: 'Saint Barthélemy',
  RI: 'Serbia',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  ZA: 'South Africa',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SE: 'Sweden',
  CH: 'Switzerland',
  TW: 'Taiwan',
  TH: 'Thailand',
  TT: 'Trinidad and Tobago',
  TR: 'Turkey',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  UK: 'United Kingdom',
  US: 'United States',
  VN: 'Vietnam',
} as const;

export type CountryCodesAbbreviationsType = keyof typeof CountryCodes;
export type CountryCodesType =
  typeof CountryCodes[CountryCodesAbbreviationsType];

const CountryCodeFilter = (codes: CountryCodesAbbreviationsType) => {
  return { filters: { addressCountryCode: [codes] } };
};

const CountryCodesAbbreviations = Object.keys(CountryCodes).reduce(
  (
    acc,
    v
  ): Record<CountryCodesAbbreviationsType, CountryCodesAbbreviationsType> => ({
    ...acc,
    [v]: v,
  }),
  {} as Record<CountryCodesAbbreviationsType, CountryCodesAbbreviationsType>
);

const CountryCodesAbbreviationsList = Object.keys(
  CountryCodes
) as CountryCodesAbbreviationsType[];

export const CountryCodesConst = {
  CountryCodes,
  CountryCodesAbbreviations,
  CountryCodesAbbreviationsList,
  AbbreviatedCountryCode: (
    country: CountryCodesType
  ): CountryCodesAbbreviationsType => {
    return CountryCodesAbbreviationsList.find(
      (e: CountryCodesAbbreviationsType) => CountryCodes[e] === country
    );
  },
  CountryCodeFilter,
} as const;
