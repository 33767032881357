import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from 'react';
import { Button } from '@axiom/ui';
import { AreaOfStudy } from '@axiom/validation';
import { func } from 'prop-types';

import SearchInput from '../SearchInput/SearchInput';
import { areaOfStudyShape } from '../../models/area-of-study';

import {
  AreasOfStudyManagementListRoot,
  AreasOfStudyManagementListStaticItem,
  AreasOfStudyManagementListDynamicScrollingItem,
  AOSList,
  AreasOfStudyManagementListItem,
  AreasOfStudyManagementListNewAreaOfStudyWrapper,
} from './AreasOfStudyManagementListStyles';

interface AreasOfStudyManagementListProps {
  areasOfStudy: AreaOfStudy[];
  selected: AreaOfStudy;
  onSelect: Dispatch<SetStateAction<AreaOfStudy | null>>;
}

export const AreasOfStudyManagementList: FC<
  AreasOfStudyManagementListProps
> = ({ areasOfStudy = [], selected, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <AreasOfStudyManagementListRoot data-test="SEARCH_LIST">
      <AreasOfStudyManagementListStaticItem>
        <SearchInput
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search areas of study"
          data-test="SEARCH_INPUT"
        />
      </AreasOfStudyManagementListStaticItem>
      <AreasOfStudyManagementListStaticItem
        onClick={() => onSelect({ id: null, name: '', category: null })}
        data-test="ADD_AREA_OF_STUDY"
      >
        <AreasOfStudyManagementListNewAreaOfStudyWrapper>
          <Button icon="plus" pattern="secondary" variation="minimal">
            Add Area of Study
          </Button>
        </AreasOfStudyManagementListNewAreaOfStudyWrapper>
      </AreasOfStudyManagementListStaticItem>
      <AreasOfStudyManagementListDynamicScrollingItem>
        <AOSList data-test="RESULTS_LIST" role="listbox">
          {areasOfStudy
            .filter(area =>
              area.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(areaOfStudy => {
              const { id, name } = areaOfStudy;
              return (
                <AreasOfStudyManagementListItem
                  key={id}
                  isSelected={selected && selected.id === id}
                  onClick={() => onSelect(areaOfStudy)}
                  data-test="RESULT_ITEM"
                >
                  {name}
                </AreasOfStudyManagementListItem>
              );
            })}
        </AOSList>
      </AreasOfStudyManagementListDynamicScrollingItem>
    </AreasOfStudyManagementListRoot>
  );
};

AreasOfStudyManagementList.defaultProps = {
  selected: null,
};

AreasOfStudyManagementList.propTypes = {
  selected: areaOfStudyShape,
  onSelect: func.isRequired,
};
