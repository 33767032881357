const CandidateProfileIssue = {
  Missing: 'Missing',
  LengthShort: 'LengthShort',
  LengthLong: 'LengthLong',
  Content: 'Content',
  Outdated: 'Outdated',
} as const;

export type CandidateProfileIssueTypes = keyof typeof CandidateProfileIssue;

export type CandidateProfileIssueValuesType =
  typeof CandidateProfileIssue[CandidateProfileIssueTypes];

const CandidateProfileIssueExperience = {
  AxiomBlankDescription: 'AxiomBlankDescription',
  MissingIndustry: 'MissingIndustry',
  NonAxiomMissing: 'NonAxiomMissing',
} as const;

export type CandidateProfileIssueExperienceTypes =
  keyof typeof CandidateProfileIssueExperience;

export type CandidateProfileIssueExperienceValuesType =
  typeof CandidateProfileIssueExperience[CandidateProfileIssueExperienceTypes];

const CandidateProfileIssueName = {
  AllCaps: 'AllCaps',
  Qualifications: 'Qualifications',
  Titles: 'Titles',
} as const;

export type CandidateProfileIssueNameTypes =
  keyof typeof CandidateProfileIssueName;

export type CandidateProfileIssueNameValuesType =
  typeof CandidateProfileIssueName[CandidateProfileIssueNameTypes];

const CandidateProfileCategory = {
  Accomplishments: 'Accomplishments',
  Availability: 'Availability',
  Education: 'Education',
  Experience: 'Experience',
  Languages: 'Languages',
  LegalTechSkills: 'LegalTechSkills',
  Name: 'Name',
  PracticeStartYear: 'PracticeStartYear',
  Preferences: 'Preferences',
  Summary: 'Summary',
} as const;

export type CandidateProfileCategoryTypes =
  keyof typeof CandidateProfileCategory;

export type CandidateProfileCategoryValuesType =
  typeof CandidateProfileCategory[CandidateProfileCategoryTypes];

export const CandidateProfileIssueConst = {
  CandidateProfileIssue,
  CandidateProfileIssueExperience,
  CandidateProfileIssueName,
  CandidateProfileCategory,
};
