import React from 'react';
import { Divider, Paragraph } from '@axiom/ui';
import { LanguageConst } from '@axiom/const';

export const TalentLanguagesItem = ({
  proficiency,
  skills,
}: {
  proficiency: typeof LanguageConst.LANGUAGE_PROFICIENCIES[keyof typeof LanguageConst.LANGUAGE_PROFICIENCIES];
  skills: typeof LanguageConst.LANGUAGE_SKILLS[keyof typeof LanguageConst.LANGUAGE_SKILLS][];
}) => (
  <Paragraph name="LANGUAGE_ITEM" as="span">
    <Divider name={`${proficiency} divider`}>
      <span data-test="LANGUAGE_PROFICIENCY">{proficiency}</span>
      {skills.length > 0 && (
        <span data-test="LANGUAGE_SKILLS">{skills.join(', ')}</span>
      )}
    </Divider>
  </Paragraph>
);
